import { useaxios } from "../../../../utils/useaxios";
export default (from, to) => reportDispatch => {
    useaxios('reports/company.php', { from, to }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            reportDispatch({ type: 'setreport', payload: { total_expense: res.total_expense, expense: res.expenses, total_income: res.total_income, income: res.incomes, total: res.total } })
        } else {
            // clear();
        }
    });
}