import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import updateaction from '../../context/action/expense/updateaction';
import { LoginContext } from '../../../home/logincontext';
import deleteaction from '../../context/action/expense/deleteaction';

const Editexpensemodal = props => {
    const { setloading } = useContext(LoginContext);

    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Modal.Header dir='rtl'>
                        <Modal.Title>گۆڕانکاری خەرجی</Modal.Title>
                    </Modal.Header>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error ? "red" : null }}>بڕ</Form.Label>
                                <Form.Control style={{ borderColor: error ? "red" : null }} type="number" placeholder="بڕ" value={props.expense_amount} onChange={(val) => props.setexpense_amount(val.target.value)} onFocus={() => seterror(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>شوێن</Form.Label>
                                <Form.Control type="text" placeholder="شوێن" value={props.expense_location} onChange={(val) => props.setexpense_location(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error1 ? "red" : null }}>بەروار</Form.Label>
                                <Form.Control style={{ borderColor: error1 ? "red" : null }} type="date" value={props.expense_exdate} placeholder="بەروار" onChange={(val) => props.setexpense_exdate(val.target.value)} onFocus={() => seterror1(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label >جۆری خەرجی</Form.Label>
                                <Form.Control type="text" placeholder="جۆری خەرجی" value={props.expense_extype} onChange={(val) => props.setexpense_extype(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>تێبینی</Form.Label>
                                <Form.Control type="text" placeholder="تێبینی" value={props.expense_note} onChange={(val) => props.setexpense_note(val.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        if (window.confirm("ئاگاداری ڕەشکردنەوە . دڵنیای ؟") == true) {
                            deleteaction(props.expense_id)(setloading);
                            props.setshow(false);
                        }
                        props.setshow(false);
                    }}>
                        ڕەشکردنەوە
                    </Button>
                    <Button variant="primary" onClick={() => {
                        if (props.expense_amount == '' || props.expense_amount < 1 || !props.expense_amount) {
                            seterror(true);
                            return false;
                        }
                        if (props.expense_exdate == '' || !props.expense_exdate) {
                            seterror1(true);
                            return false;
                        }
                        // createaction(amount, extype, location, exdate, note)(setloading);
                        updateaction(props.expense_amount, props.expense_extype, props.expense_location, props.expense_exdate, props.expense_note, props.expense_id)(setloading);
                        props.setshow(false);
                    }}>
                        گۆڕانکاری
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Editexpensemodal