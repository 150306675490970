import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { LoginContext } from '../../../home/logincontext';
import { GlobalContext } from '../../context/provider';
import numberbeautifuly from '../../../utils/numberbeautify';
import moment from 'moment';

const Income_report_detail_modal = props => {
    const { reportState, reportDispatch } = useContext(GlobalContext);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Modal.Header dir='rtl'>
                        <Modal.Title>لیستی داهات</Modal.Title>
                    </Modal.Header>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        {JSON.stringify(reportState.income)}
                        <Table striped bordered hover size="sm">
                            <thead>
                                <th>ناو</th>
                                <th>کۆد</th>
                                <th>پۆل</th>
                                <th>بڕ</th>
                                <th>جۆر</th>
                                <th>بەروار</th>
                            </thead>
                            <tbody>
                                {reportState.incomes.map((lis) => (
                                    lis.type == 'student' ?
                                        <tr>
                                            <td>{lis.student_name}</td>
                                            <td>{lis.student_code}</td>
                                            <td>{lis.class_name}</td>
                                            <td>{numberbeautifuly(lis.student_income_amount)} IQD</td>
                                            <td>{lis.student_income_type == 'scissors' ? "قیست" : lis.student_income_type == 'punish' ? "سزا" : "هی دیکە"}</td>
                                            <td>{moment(lis.date).format('YYYY-MM-DD')}</td>
                                        </tr>
                                        : null
                                ))}
                                <tr>
                                    <th colSpan={4}>کۆی داهات</th>
                                    <td colSpan={2}>{numberbeautifuly(Math.abs(reportState.total_income))} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                        {/* <tr>
                                    <th>سزا</th>
                                    <td> {numberbeautifuly(reportState.income_punish)} IQD</td>
                                </tr>
                                <tr>
                                    <th>قیست</th>
                                    <td>{numberbeautifuly(reportState.income_scissors)} IQD</td>
                                </tr>
                                <tr>
                                    <th>داهاتی تر</th>
                                    <td>{numberbeautifuly(reportState.income_other)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی داهات</th>
                                    <td>{numberbeautifuly(Math.abs(reportState.total_income))} IQD</td>
                                </tr> */}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => {
                        props.setshow(false);
                        props.setreporttype('income');
                        props.printit();
                    }}>
                        پرینت
                    </Button>
                    <Button variant="primary" onClick={() => {
                        props.setreporttype('');
                        props.setshow(false);
                    }}>
                        داخستن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Income_report_detail_modal