import { useaxios } from "../../../../utils/useaxios";
export default () => initDispatch => studentDispatch => teacherDispatch => employDispatch => dailyexpenseDispatch => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    useaxios('department/init.php', { department_id: 4, today }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            initDispatch({ type: 'setclass', payload: res.class });
            studentDispatch({ type: 'setstudent', payload: { student: res.student, active_student: res.active_student } });
            teacherDispatch({ type: 'setteacher', payload: { teacher: res.teacher, active_teacher: res.active_teacher } });
            employDispatch({ type: 'setemploy', payload: { employ: res.employ, active_employ: res.active_employ } });
            dailyexpenseDispatch({ type: 'setdaily', payload: { daily_expense: res.daily_expense } });
        } else {
            // clear();
        }
    });
}