import React, { useContext, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Logo from '../../../margretlogo.png';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import { GlobalContext } from '../../context/provider';
import moment from "moment";
import '../css/onprinttoggle.css';
import numberbeautifuly from '../../../utils/numberbeautify';

const Income_report_detail_print = props => {
    const { reportState, reportDispatch } = useContext(GlobalContext);

    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return (
        <div className='print_show'>
            <Container>
                <Row className='justify-content-md-center mt-3'>
                    <Image src={Logo} style={{ width: 110, height: 100 }} />
                    <center><span>لیستی داهاتی خوێندنگە لە بەرواری {moment(props.from).format('YYYY-MM-DD')} بۆ بەرواری {moment(props.to).format('YYYY-MM-DD')}</span></center>
                </Row>
                <Row className='justify-content-md-center mt-4'>
                    <table>
                        <thead style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            <th>ناو</th>
                            <th>کۆد</th>
                            <th>پۆل</th>
                            <th>بڕ</th>
                            <th>جۆر</th>
                            <th>بەروار</th>
                        </thead>
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            {reportState.incomes.map((lis) => (
                                lis.type == 'student' ?
                                    <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                        <td>{lis.student_name}</td>
                                        <td>{lis.student_code}</td>
                                        <td>{lis.class_name}</td>
                                        <td>{numberbeautifuly(lis.student_income_amount)} IQD</td>
                                        <td>{lis.student_income_type == 'scissors' ? "قیست" : lis.student_income_type == 'punish' ? "سزا" : "هی دیکە"}</td>
                                        <td>{moment(lis.date).format('YYYY-MM-DD')}</td>
                                    </tr>
                                    : null
                            ))}
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th colSpan={4}>کۆی داهات</th>
                                <td colSpan={2}>{numberbeautifuly(Math.abs(reportState.total_income))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>سزا</th>
                                <td> {numberbeautifuly(reportState.income_punish)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>قیست</th>
                                <td>{numberbeautifuly(reportState.income_scissors)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>داهاتی تر</th>
                                <td>{numberbeautifuly(reportState.income_other)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی داهات</th>
                                <td>{numberbeautifuly(Math.abs(reportState.total_income))} IQD</td>
                            </tr> */}
                </Row>
                <div style={{ width: '100%', marginTop: '5vh', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%' }}>
                        <span>سارا سعدی عمر<br />
                            ژمێریار</span>
                    </div>
                    <div style={{ width: '50%', display: 'flex', justifyContent: "flex-end" }}>
                        <span>ئاڤان ئەحمەد حەمە<br />
                            بەڕێوەبەر</span>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Income_report_detail_print