import { useaxios } from "../../../../utils/useaxios";
export default (date) => employDispatch => {
    useaxios('employ/deletesalarylist.php', { date }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            window.open('https://managment.margaret.edu.krd/employ/', "_self");
            // employDispatch({ type: 'setsalarylist', payload: res.employs });
        } else {
            // clear();
        }
    });
}