import React, { useContext } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { BsBoxArrowRight } from "react-icons/bs";
import { MdRefresh } from "react-icons/md";
import Menu from './menu';
import GlobalProvider from '../context/provider';
import { LoginContext } from '../../home/logincontext';
import './css/onprinttoggle.css';
import Expense from './expense/expense';
import Income from './income/income';
import Mainreport from './mainreport/mainreport';
import Income_report from './income_report/income_report';
import Expense_report from './expense_report/expense_report';

const Projecthome = () => {
    const { setloading, clear } = useContext(LoginContext);
    return (
        <Router>
            <Switch>
                <GlobalProvider>
                    <div dir="rtl" style={{ width: "100%", display: "flex" }}>
                        <div className='home_menu_container'>
                            <Menu />
                        </div>
                        <div className='home_main_container'>
                            <div className='print_hide' style={{ width: "100%", height: "7vh", background: "#27293d", "box-shadow": " 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12)", color: "#fff", display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: "5em", paddingRight: "2em" }}>
                                <div>
                                    <Link to="/" style={{ color: "#fff", cursor: "pointer" }} onClick={() => {
                                        // setloading(true);
                                        // setloading(false);
                                        window.open('/', "_self");
                                    }}> <FaHome size="2em" /></Link>
                                </div>
                                <div>
                                    <Link to="/" style={{ color: "#fff" }}><BsBoxArrowRight size="2em" style={{ cursor: "pointer" }} onClick={() => { clear(); }} /></Link>
                                </div>
                            </div>
                            <Route path="/project/" exact>
                                <Income />
                            </Route>
                            <Route path="/project/expense" >
                                <Expense />
                            </Route>
                            <Route path="/project/income_report" >
                                <Income_report />
                            </Route>
                            <Route path="/project/expense_report" >
                                <Expense_report />
                            </Route>
                            <Route path="/project/report" >
                                <Mainreport />
                            </Route>
                        </div>
                    </div>
                </GlobalProvider>
            </Switch>
        </Router>
    )
}

export default Projecthome