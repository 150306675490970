import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Addteachermodal from './addteachermodal';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import Editteachermodal from './editteachermodal';
import changestateaction from '../../context/action/teacher/changestateaction';
import searchaction from '../../context/action/teacher/searchaction';
import Teacherdetailmodal from './teacherdetailmodal';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdEditNote } from "react-icons/md";
import Punishmodal from './punishmodal';
import numberbeautifuly from '../../../utils/numberbeautify';

const Expense = () => {
    const { initState, initDispatch } = useContext(GlobalContext);
    const { setloading } = useContext(LoginContext);

    const [show, setshow] = useState(false);
    const [show1, setshow1] = useState(false);
    const [show2, setshow2] = useState(false);
    const [search, setsearch] = useState();
    const [expense_id, setexpense_id] = useState();
    const [name, setname] = useState();
    const [amount, setamount] = useState();
    const [invoice, setinvoice] = useState();
    const [location, setlocation] = useState();
    const [note, setnote] = useState();
    const [date, setdate] = useState();
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const [from, setfrom] = useState(today);
    const [to, setto] = useState(today);

    return (
        <div dir='rtl'>
            <Container>
                <Punishmodal show={show} setshow={setshow} />
                <Editteachermodal show={show1} setshow={setshow1} name={name} setname={setname} amount={amount} setamount={setamount} invoice={invoice} setinvoice={setinvoice} location={location} setlocation={setlocation} note={note} setnote={setnote} date={date} setdate={setdate} expense_id={expense_id} />
                <Row className='mt-4'>
                    <center><h3><b>  کەریمی عەلەکە - خەرجی </b></h3></center>
                </Row>
                <Row className='mt-3'>
                    <Col lg={3} className="mt-4">
                        <Button onClick={() => {
                            setshow(true);
                        }}>زیادکردن</Button>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>لە بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="لە بەرواری" value={from} onChange={(val) => { searchaction(val.target.value, to)(initDispatch); setfrom(val.target.value) }} />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>بۆ بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="بۆ بەرواری" value={to} onChange={(val) => { searchaction(from, val.target.value)(initDispatch); setto(val.target.value) }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='justify-content-md-center mt-5'>
                    <Col lg={11}>
                        <Table striped bordered hover size="lg" style={{ textAlign: 'center' }}>
                            <thead>
                                <tr>
                                    <th>ناوی کەس</th>
                                    <th>بڕی خەرجی</th>
                                    <th>شوێن </th>
                                    <th>جۆری خەرجی</th>
                                    <th>بەروار</th>
                                    <th>تێبینی</th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: 12 }}>
                                {initState.expense.map((lis) => (
                                    <tr style={{ cursor: 'pointer' }} onClick={() => {
                                        setexpense_id(lis.boss_expense_id);
                                        setname(lis.boss_expense_nameandpurpose);
                                        setamount(lis.boss_expense_amount);
                                        setinvoice(lis.boss_expense_invoiceno);
                                        setlocation(lis.boss_expense_location);
                                        setnote(lis.boss_expense_note);
                                        setdate(lis.boss_expense_date);
                                        setshow1(true);
                                    }}>
                                        <td>{lis.boss_expense_nameandpurpose}</td>
                                        <td>{numberbeautifuly(lis.boss_expense_amount)} IQD</td>
                                        <td>{lis.boss_expense_invoiceno}</td>
                                        <td>{lis.boss_expense_location}</td>
                                        <td>{lis.boss_expense_date}</td>
                                        <td>{lis.boss_expense_note}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Expense