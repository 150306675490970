import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import updateaction from '../../context/action/certificate/updateaction';

const Editcertificatemodal = (props) => {
    const { recaller, setrecaller } = useContext(GlobalContext);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    {/* {JSON.stringify(initState.class[0].class_id)} */}
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Col lg={7} className="mt-3">
                            <Form.Group controlId="formBasicName">
                                <Form.Label>بڕوانامە</Form.Label>
                                <Form.Control type="text" placeholder="بڕوانامە" value={props.certificatename} onChange={(val) => props.setcertificatename(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={7} className="mt-3">
                            <Form.Group controlId="formBasicName">
                                <Form.Label>بڕی کۆمیشن</Form.Label>
                                <Form.Control type="number" placeholder="بڕی کۆمیشن" value={props.certificateamount} onChange={(val) => props.setcertificateamount(val.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        updateaction(props.certificatename, props.certificateamount, props.certificateid)(recaller)(setrecaller);
                        props.setshow(false);
                    }}>
                        گۆڕانکاری
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Editcertificatemodal