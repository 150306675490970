const Employreducer = (state, { type, payload }) => {
    switch (type) {
        case 'setemploy':
            return { ...state, employ: payload.employ, active_employ: payload.active_employ };
        case 'setsearch':
            return { ...state, employ: payload };
        case 'setactivesearch':
            return { ...state, active_employ: payload };
        default:
            return state;
    }
}

export default Employreducer;