import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { LoginContext } from '../../../home/logincontext';
import { GlobalContext } from '../../context/provider';
import numberbeautifuly from '../../../utils/numberbeautify';

const Mainreport_detail_modal = props => {
    const { reportState, reportDispatch } = useContext(GlobalContext);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr>
                                    <th>کۆی داهات</th>
                                    <td>{numberbeautifuly(reportState.income.reduce((a, v) => a = Number(a) + Number(v.radio_income_amount), 0))} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی خەرجی</th>
                                    <td>{numberbeautifuly(reportState.expense.reduce((a, v) => a = Number(a) + Number(v.radio_expense_amount), 0))} IQD</td>
                                </tr>
                                <tr>
                                    <th>{reportState.income.reduce((a, v) => a = Number(a) + Number(v.radio_income_amount), 0) > reportState.expense.reduce((a, v) => a = Number(a) + Number(v.radio_expense_amount), 0) ? "کۆی ماوە" : "کۆی کورت هێنان"}</th>
                                    <td>{numberbeautifuly(Math.abs(reportState.income.reduce((a, v) => a = Number(a) + Number(v.radio_income_amount), 0) - reportState.expense.reduce((a, v) => a = Number(a) + Number(v.radio_expense_amount), 0)))} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => {
                        props.setshow(false);
                        props.setreporttype('total');
                        props.printit();
                    }}>
                        پرینت
                    </Button>
                    <Button variant="primary" onClick={() => {
                        props.setshow(false);
                    }}>
                        داخستن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Mainreport_detail_modal