import { useaxios } from "../../../../utils/useaxios";
export default (student_id, amount, student_expense_date, note) => setloading => {
    setloading(true);
    useaxios('student_expense/create.php', { student_expense_type: 'scissors', student_expense_amount: amount, student_expense_note: note, student_expense_date, student_id, department_id: 3 }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}