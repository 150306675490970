import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { LoginContext } from '../../../home/logincontext';
import createaction from '../../context/action/income/createaction';

const Addincomemodal = props => {
    const { setloading } = useContext(LoginContext);

    const [name, setname] = useState();
    const [amount, setamount] = useState();
    const [date, setdate] = useState();
    const [invoice, setinvoice] = useState();
    const [note, setnote] = useState();
    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ناوی کۆمپانیا</Form.Label>
                                <Form.Control type="text" placeholder="ناوی کۆمپانیا" value={name} onChange={(val) => setname(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error ? "red" : null }}>بڕ</Form.Label>
                                <Form.Control style={{ borderColor: error ? "red" : null }} type="number" placeholder="بڕ" value={amount} onChange={(val) => setamount(val.target.value)} onFocus={() => seterror(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error1 ? "red" : null }}>بەروار</Form.Label>
                                <Form.Control style={{ borderColor: error1 ? "red" : null }} type="date" placeholder="بەروار" value={date} onChange={(val) => setdate(val.target.value)} onFocus={() => seterror1(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ژمارەی وەسڵ</Form.Label>
                                <Form.Control type="text" placeholder="ژمارەی وەسڵ" value={invoice} onChange={(val) => setinvoice(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>تێبینی</Form.Label>
                                <Form.Control type="text" placeholder="تێبینی" value={note} onChange={(val) => setnote(val.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        if (amount == '' || amount < 0 || !amount) {
                            seterror(true);
                            return false;
                        }
                        if (date == '' || !date) {
                            seterror1(true);
                            return false;
                        }
                        createaction(name, amount, date, invoice, note)(setloading);
                        props.setshow(false);
                    }}>
                        زیادکردن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Addincomemodal