import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import numberbeautifuly from '../../../utils/numberbeautify';

const Expense_report_list = () => {
    const { reportState, reportDispatch } = useContext(GlobalContext);
    return (
        <div dir="rtl">
            <Container className='print_hide'>
                <Row className='justify-content-md-center mt-5'>
                    <Col lg={11}>
                        <Table striped bordered hover size="lg" style={{ textAlign: 'center' }} >
                            <thead>
                                <tr>
                                    <th>ناوی کەس</th>
                                    <th>بڕی خەرجی</th>
                                    <th>ژمارەی پسوڵە</th>
                                    <th>بەروار</th>
                                    <th>تێبینی</th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: 12 }}>
                                {reportState.expense_report.map((lis) => (
                                    <tr style={{ cursor: 'pointer' }}>
                                        <td>{lis.company_expense_purpose}</td>
                                        <td>{numberbeautifuly(lis.company_expense_amount)} IQD</td>
                                        <td>{lis.company_expense_invoiceno}</td>
                                        <td>{lis.company_expense_date}</td>
                                        <td>{lis.company_expense_note}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Expense_report_list