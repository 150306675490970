import { useaxios } from "../../../../utils/useaxios";
export default () => initDispatch => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    useaxios('setting/init.php',).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            initDispatch({ type: 'setsystem_option', payload: { system_option: res.init, certificate: res.certificate } })
        } else {
            // clear();
        }
    });
}