import React, { useContext, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Logo from '../../../margretlogo.png';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import { GlobalContext } from '../../context/provider';
import moment from "moment";
import '../css/onprinttoggle.css';
import numberbeautifuly from '../../../utils/numberbeautify';

const Mainreport_detail_print = props => {
    const { reportState, reportDispatch } = useContext(GlobalContext);
    return (
        <div className='print_show'>
            <Container>
                <Row className='justify-content-md-center mt-3'>
                    <Image src={Logo} style={{ width: 110, height: 100 }} />
                    <center><span> کۆی حساباتی پڕۆژەکانی مارگرێت لە بەرواری {moment(props.from).format('YYYY-MM-DD')} بۆ بەرواری {moment(props.to).format('YYYY-MM-DD')}</span></center>
                </Row>
                <Row className='justify-content-md-center mt-4'>
                    <table>
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی داهات</th>
                                <td>{numberbeautifuly(reportState.income.reduce((a, v) => a = Number(a) + Number(v.project_income_amount), 0))} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی خەرجی</th>
                                <td>{numberbeautifuly(reportState.expense.reduce((a, v) => a = Number(a) + Number(v.project_expense_amount), 0))} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>{reportState.income.reduce((a, v) => a = Number(a) + Number(v.project_income_amount), 0) > reportState.expense.reduce((a, v) => a = Number(a) + Number(v.project_expense_amount), 0) ? "کۆی ماوە" : "کۆی کورت هێنان"}</th>
                                <td>{numberbeautifuly(Math.abs(reportState.income.reduce((a, v) => a = Number(a) + Number(v.project_income_amount), 0) - reportState.expense.reduce((a, v) => a = Number(a) + Number(v.project_expense_amount), 0)))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <div style={{ width: '100%', marginTop: '5vh', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%' }}>
                        <span>سارا سعدی عمر<br />
                            ژمێریار</span>
                    </div>
                    <div style={{ width: '50%', display: 'flex', justifyContent: "flex-end" }}>
                        <span>ئاڤان ئەحمەد حەمە<br />
                            بەڕێوەبەر</span>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Mainreport_detail_print