import axios from 'axios';
import React, { useState, useEffect, createContext } from 'react';
import constatnts from '../../../utils/constatnts';
export const Summarycontext = createContext();
export const Summaryprovider = ({ children }) => {
    const [totalsummary, settotalsummary] = useState(0);
    const [showsummary, setshowsummary] = useState(false);
    const getsummary = (from, to) => {
        axios.post(constatnts.host + 'reports/mainreport.php', {
            from, to
        }).then((res) => {
            console.log(res);
            if (res.data.status == 'ok') {
                settotalsummary(res.data);
                setshowsummary(true);
            }
        });
    }
    return <Summarycontext.Provider value={{ getsummary, totalsummary, settotalsummary, showsummary, setshowsummary }} >{children}</Summarycontext.Provider>
}