import { useaxios } from "../../../../utils/useaxios";
export default (company_name, invoiceno, from, to) => reportDispatch => {
    useaxios('company_income/income_report.php', { company_name, invoiceno, from, to }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            reportDispatch({ type: 'setincomereport', payload: res.data });
        } else {
            // clear();
        }
    });
}