import axios from 'axios';
import React, { useState, useEffect, createContext } from 'react';
import constatnts from '../../../utils/constatnts';
export const Reportcontext = createContext();
export const Reportprovider = ({ children }) => {
    const [totalreport, settotalreport] = useState(0);
    const [showreport, setshowreport] = useState(false);
    const getreport = (from, to) => {
        axios.post(constatnts.host + 'reports/mainreport.php', {
            from, to
        }).then((res) => {
            console.log(res);
            if (res.data.status == 'ok') {
                settotalreport(res.data);
                setshowreport(true);
            }
        });
    }
    return <Reportcontext.Provider value={{ getreport, totalreport, settotalreport, showreport, setshowreport }} >{children}</Reportcontext.Provider>
}