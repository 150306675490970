import { useEffect, useReducer, createContext, useState } from "react";
import initaction from "./action/init/initaction";
import employstate from "./initialState/employstate";
import Employreducer from "./reducer/employreducer";

export const GlobalContext = createContext({});
const GlobalProvider = ({ children }) => {

    const [recaller, setrecaller] = useState(0);

    const [employState, employDispatch] = useReducer(Employreducer, employstate);
    useEffect(() => {
        initaction()(employDispatch);
    }, [recaller]);

    return <GlobalContext.Provider value={{ employState, employDispatch, recaller, setrecaller }}>{children}</GlobalContext.Provider>
}

export default GlobalProvider