import { useaxios } from "../../../../utils/useaxios";
export default (from, to) => reportDispatch => {
    useaxios('reports/department.php', { from, to, department_id: 3 }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            console.log(res);
            reportDispatch({ type: 'setreport', payload: { income_punish: res.income_punish, income_other: res.income_other, income_scissors: res.income_scissors, expense_punish: res.expense_punish, expense_other: res.expense_other, expense_scissors: res.expense_scissors, expense_salary: res.expense_salary, incomes: res.incomes, expenses: res.expenses, total_income: res.total_income, total_expense: res.total_expense, balance: res.balance, total_loan: res.total_loan, loan: res.loan, daily_expense: res.daily_expense, student_stat: res.student_stat } })
        } else {
            // clear();
        }
    });
}