import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdEditNote } from "react-icons/md";
import updateinitaction from '../../context/action/init/updateinitaction';

const System_option = () => {
    const { initState, recaller, setrecaller } = useContext(GlobalContext);
    const [comtion, setcomtion] = useState(initState.system_option.length > 0 ? initState.system_option[0].system_option_value : 0);
    const [mincomtion, setmincomtion] = useState(initState.system_option.length > 0 ? initState.system_option[1].system_option_value : 0);

    return (
        <div dir='rtl'>
            <Container>
                <Row className='justify-content-md-center mt-5'>
                    <Col lg={7}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>بڕی کۆمیشن بۆ هەر بەشە وانەیەک</Form.Label>
                            <Form.Control type="number" placeholder="بڕی کۆمیشن بۆ هەر بەشە وانەیەک" value={comtion} onChange={(val) => setcomtion(val.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='justify-content-md-center mt-2'>
                    <Col lg={7}>
                        <Button variant="primary" onClick={() => {
                            updateinitaction(comtion)(recaller)(setrecaller)
                        }}>
                            گۆڕانکاری
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default System_option