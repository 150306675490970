export default {
    income_punish: 0,
    income_other: 0,
    income_scissors: 0,
    expense_punish: 0,
    expense_other: 0,
    expense_scissors: 0,
    expense_salary: 0,
    incomes: [],
    expenses: [],
    loan: [],
    total_income: 0,
    total_expense: 0,
    balance: 0,
    total_loan: 0,
    daily_expense: 0,
    isshow: false,
    student_stat: []
}