import React, { useContext, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import './css/menu.css';
import './css/onprinttoggle.css';
import Image from 'react-bootstrap/Image';
import Logo from '../../margretlogo.png';
const Menu = () => {
    const location = useLocation();

    return (
        <div className="usr_menu print_hide">
            <br />
            <center><Image src={Logo} style={{ width: 80, height: 90 }} /></center>
            <center><h6>خەرجی و داهاتی پرۆژە </h6></center>
            <ul>
                <Link to="/project/"><li className={location.pathname == '/project/' ? 'aactive' : null}>داهات</li></Link>
                <Link to="/project/expense"><li className={location.pathname == '/project/expense' ? 'aactive' : null}>خەرجی</li></Link>
                <Link to="/project/income_report"><li className={location.pathname == '/project/income_report' ? 'aactive' : null}>ڕاپۆرتی داهات</li></Link>
                <Link to="/project/expense_report"><li className={location.pathname == '/project/expense_report' ? 'aactive' : null}>ڕاپۆرتی خەرجی</li></Link>
                <Link to="/project/report"><li className={location.pathname == '/project/report' ? 'aactive' : null}>ژمێریاری گشتی</li></Link>
            </ul>
        </div>
    )
}

export default Menu