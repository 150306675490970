import { useaxios } from "../../../../utils/useaxios";
export default (purpose, invoiceno, from, to) => reportDispatch => {
    useaxios('boss_income/income_report.php', { purpose, invoiceno, from, to }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            reportDispatch({ type: 'setincomereport', payload: res.data });
        } else {
            // clear();
        }
    });
}