import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdEditNote } from "react-icons/md";
import addaction from '../../context/action/certificate/addaction';

const Certificateform = () => {
    const { recaller, setrecaller } = useContext(GlobalContext);
    const [certificatename, setcertificatename] = useState();
    const [certificateamount, setcertificateamount] = useState(0);
    return (
        <div dir="rtl">
            <Container>
                <Row className='justify-content-md-center mt-4'>
                    <Col lg={4} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>بڕوانامە</Form.Label>
                            <Form.Control type="text" placeholder="بڕوانامە" value={certificatename} onChange={(val) => setcertificatename(val.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>بڕی کۆمیشن</Form.Label>
                            <Form.Control type="number" placeholder="بڕی کۆمیشن" value={certificateamount} onChange={(val) => setcertificateamount(val.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mt-5">
                        <Button onClick={() => {
                            addaction(certificatename, certificateamount)(recaller)(setrecaller);
                        }}>زیادکردن</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Certificateform