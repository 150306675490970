import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import createaction from '../../context/action/employ/createaction';

const Addemploymodal = (props) => {
    const { employState, recaller, setrecaller } = useContext(GlobalContext);
    const { loading, setloading } = useContext(LoginContext);

    const [name, setname] = useState();
    const [phone1, setphone1] = useState();
    const [job, setjob] = useState();
    const [certificate, setcertificate] = useState(0);
    const [location, setlocation] = useState(0);
    const [email, setemail] = useState();
    const [salary, setsalary] = useState();
    const [service, setservice] = useState();
    const [aregment, setaregment] = useState();
    const [lesson_part, setlesson_part] = useState();
    const [note, setnote] = useState();

    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);
    const [error2, seterror2] = useState(false);
    const [error3, seterror3] = useState(false);
    const [error4, seterror4] = useState(false);
    const [error5, seterror5] = useState(false);
    const [error6, seterror6] = useState(false);
    const [error7, seterror7] = useState(false);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    {/* {JSON.stringify(initState.class[0].class_id)} */}
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error ? "red" : null }}>ناو</Form.Label>
                                <Form.Control style={{ borderColor: error ? "red" : null }} type="text" placeholder="ناو" value={name} onChange={(val) => setname(val.target.value)} onFocus={() => seterror(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ئیمەیڵ</Form.Label>
                                <Form.Control type="text" placeholder="ئیمەیڵ" value={email} onChange={(val) => setemail(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error5 ? "red" : null }}>بڕوانامە</Form.Label>
                                <Form.Select onChange={(val) => { setcertificate(val.target.value); seterror5(false); }} dir="ltr" style={{ borderColor: error5 ? "red" : null }}>
                                    <option value={0}>بڕوانامە</option>
                                    {employState.certificate.map((lis) => (
                                        <option value={lis.certificate_id}>{lis.certificate_name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error6 ? "red" : null }}>شوێن</Form.Label>
                                <Form.Select onChange={(val) => { setlocation(val.target.value); seterror6(false); }} dir="ltr" style={{ borderColor: error6 ? "red" : null }}>
                                    <option value={0}>شوێن</option>
                                    {employState.department.map((lis) => (
                                        <option value={lis.department_id}>{lis.department_name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error1 ? "red" : null }}>ژمارە تەلەفۆن </Form.Label>
                                <Form.Control style={{ borderColor: error1 ? "red" : null }} type="number" placeholder="ژمارە تەلەفۆن " value={phone1} onChange={(val) => setphone1(val.target.value)} onFocus={() => seterror1(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>پیشە</Form.Label>
                                <Form.Control type="text" placeholder="پیشە" value={job} onChange={(val) => setjob(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error3 ? "red" : null }}>موچە</Form.Label>
                                <Form.Control style={{ borderColor: error3 ? "red" : null }} type="number" placeholder="موچە" value={salary} onChange={(val) => setsalary(val.target.value)} onFocus={() => seterror3(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error4 ? "red" : null }}>بەشەوانە</Form.Label>
                                <Form.Control style={{ borderColor: error4 ? "red" : null }} type="number" placeholder="بەشەوانە" value={lesson_part} onChange={(val) => setlesson_part(val.target.value)} onFocus={() => seterror4(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error2 ? "red" : null }}>خزمەت</Form.Label>
                                <Form.Control style={{ borderColor: error2 ? "red" : null }} type="number" placeholder="خزمەت" value={service} onChange={(val) => setservice(val.target.value)} onFocus={() => seterror2(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error7 ? "red" : null }}>کۆمیشنی ڕێککەوتن</Form.Label>
                                <Form.Control style={{ borderColor: error7 ? "red" : null }} type="number" placeholder="کۆمیشنی ڕێککەوتن" value={aregment} onChange={(val) => setaregment(val.target.value)} onFocus={() => seterror7(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>تێبینی</Form.Label>
                                <Form.Control type="text" placeholder="تێبینی" value={note} onChange={(val) => setnote(val.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        if (name == '' || !name) {
                            seterror(true);
                            return false;
                        }
                        if (certificate == 0) {
                            seterror5(true);
                            return false;
                        }
                        if (location == 0) {
                            seterror6(true);
                            return false;
                        }
                        if (salary == '' || salary < 1 || !salary) {
                            seterror3(true);
                            return false;
                        }
                        if (lesson_part == '' || lesson_part < 0 || !lesson_part) {
                            seterror4(true);
                            return false;
                        }
                        if (service == '' || service < 0 || !service) {
                            seterror2(true);
                            return false;
                        }
                        if (aregment == '' || aregment < 0 || !aregment) {
                            seterror7(true);
                            return false;
                        }
                        createaction(name, phone1, job, lesson_part, email, salary, aregment, service, certificate, note, location)(recaller)(setloading);
                        props.setshow(false);
                    }}>
                        زیادکردن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Addemploymodal