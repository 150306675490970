import React, { useContext } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { BsBoxArrowRight } from "react-icons/bs";
import { MdRefresh } from "react-icons/md";
import Menu from './menu';
import Student from './student/student';
import GlobalProvider from '../context/provider';
import Teacher from './teacher/teacher';
import Employ from './employ/employ';
import Teacheraccount from './teacher/teacheraccount';
import Studentaccount from './student/studentaccount';
import Employaccount from './employ/employaccount';
import Mainreport from './mainreport/mainreport';
import { LoginContext } from '../../home/logincontext';
import './css/onprinttoggle.css';
import Expense from './expense/expense';
const SchoolHome = () => {
    const { setloading, clear } = useContext(LoginContext);
    return (
        <Router>
            <Switch>
                <GlobalProvider>
                    <div dir="rtl" style={{ width: "100%", display: "flex" }}>
                        <div className='home_menu_container'>
                            <Menu />
                        </div>
                        <div className='home_main_container'>
                            <div className='print_hide' style={{ width: "100%", height: "7vh", background: "#27293d", "box-shadow": " 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12)", color: "#fff", display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: "5em", paddingRight: "2em" }}>
                                <div>
                                    <Link to="/" style={{ color: "#fff", cursor: "pointer" }} onClick={() => {
                                        window.open('/', "_self");
                                    }}> <FaHome size="2em" /></Link>
                                </div>
                                <div>
                                    <Link to="/" style={{ color: "#fff" }}><BsBoxArrowRight size="2em" style={{ cursor: "pointer" }} onClick={() => { clear(); }} /></Link>
                                </div>
                            </div>
                            <Route path="/school/" exact>
                                <Student />
                            </Route>
                            {/* <Route path="/school/teacher" >
                                <Teacher />
                            </Route>
                            <Route path="/school/employ" >
                                <Employ />
                            </Route> */}
                            {/* <Route path="/school/teacheraccount" >
                                <Teacheraccount />
                            </Route> */}
                            <Route path="/school/studentaccount" >
                                <Studentaccount />
                            </Route>
                            {/* <Route path="/school/employaccount" >
                                <Employaccount />
                            </Route> */}
                            <Route path="/school/mainreport" >
                                <Mainreport />
                            </Route>
                            <Route path="/school/dailyexpense">
                                <Expense />
                            </Route>
                        </div>
                    </div>
                </GlobalProvider>
            </Switch>
        </Router>
    )
}

export default SchoolHome