import { useaxios } from "../../../../utils/useaxios";
export default (student_id, amount, boss_income_nameandpurpose, boss_income_invoiceno, boss_income_date, note) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    setloading(true);
    useaxios('boss_income/create.php', { boss_income_type: 'other', boss_income_nameandpurpose, boss_income_amount: amount, boss_income_invoiceno, boss_income_note: note, boss_income_date: boss_income_date }).then((res) => {
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}