import { useaxios } from "../../../../utils/useaxios";
export default (name, location, extype, from, to) => reportDispatch => {
    useaxios('boss_expense/expense_report.php', { name, location, extype, from, to }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            reportDispatch({ type: 'setexpensereport', payload: res.data })
        } else {
            // clear();
        }
    });
}