import { useaxios } from "../../../../utils/useaxios";
export default (from, to) => reportDispatch => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    useaxios('reports/project.php', { from, to }).then((res) => {
        if (res.status == "ok") {
            reportDispatch({ type: 'setreport', payload: { total_expense: res.total_expense, expense: res.expenses, total_income: res.total_income, income: res.incomes, total: res.total } });
        } else {
            // clear();
        }
    });
}