import { useaxios } from "../../../../utils/useaxios";
export default (student_id, amount, student_income_date, note) => setloading => {
    setloading(true);
    useaxios('student_income/create.php', { student_income_type: 'punish', student_income_amount: amount, student_income_note: note, student_income_date, student_id, department_id: 3 }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}