import { useaxios } from "../../../../utils/useaxios";
export default (employ_id) => setloading => employDispatch => setshow3 => {
    // setloading(true);
    useaxios('employ/chekout.php', { employ_id }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            setshow3(true);
            employDispatch({ type: 'setchekout', payload: res.employ_chekout });
        } else {
            // clear();
        }
        // setloading(false);
    });
}