import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { LoginContext } from '../../../home/logincontext';
import { GlobalContext } from '../../context/provider';
import numberbeautifuly from '../../../utils/numberbeautify';

const Income_report_detail_modal = props => {
    const { reportState, reportDispatch } = useContext(GlobalContext);

    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>ناوی کۆمپانیا</th>
                                    <th>بڕ</th>
                                    <th>بەروار</th>
                                    <th>ژمارەی پسوڵە</th>
                                    <th>تێبینی</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportState.income.map((lis) => (
                                    <tr>
                                        <td>{lis.project_income_name}</td>
                                        <td>{numberbeautifuly(lis.project_income_amount)} IQD</td>
                                        <td>{lis.project_income_date}</td>
                                        <td>{lis.project_income_invoice}</td>
                                        <td>{lis.project_income_note}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td>کۆی گشتی</td>
                                    <td colSpan={4} style={{ textAlign: 'center' }}>{numberbeautifuly(reportState.total_income)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => {
                        props.setshow(false);
                        props.setreporttype('income');
                        props.printit();
                    }}>
                        پرینت
                    </Button>
                    <Button variant="primary" onClick={() => {
                        props.setshow(false);
                    }}>
                        داخستن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Income_report_detail_modal