import { useaxios } from "../../../../utils/useaxios";
export default (company_income_purpose, company_income_amount, company_income_invoiceno, company_income_note, company_income_date) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setloading(true);
    useaxios('company_income/create.php', { company_income_purpose, company_income_amount, company_income_invoiceno, company_income_note, company_income_date }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}