import { useaxios } from "../../../../utils/useaxios";
export default (certificate_name, certificate_amount) => recaller => setrecaller => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    useaxios('certificate/create.php', { certificate_name, certificate_amount }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            setrecaller(recaller + 1);
        } else {
            // clear();
        }
    });
}