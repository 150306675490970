import React, { useContext, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Logo from '../../../margretlogo.png';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { LoginContext } from '../../../home/logincontext';
import { Reportcontext } from './reportcontext';
import moment from "moment";
import '../css/onprinttoggle.css';
import numberbeautifuly from '../../../utils/numberbeautify';

const Reportdetailprint = props => {
    const { getreport, totalreport, settotalreport, showreport, setshowreport } = useContext(Reportcontext);
    return (
        <div className='print_show'>
            <Container>
                <Row className='justify-content-md-center mt-3'>
                    <Image src={Logo} style={{ width: 110, height: 100 }} />
                    <center><span> کۆی حساباتی مارگرێت لە بەرواری {moment(props.from).format('YYYY-MM-DD')} بۆ بەرواری {moment(props.to).format('YYYY-MM-DD')}</span></center>
                </Row>
                <Row className='justify-content-md-center mt-4'>
                    <table>
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            <tr style={{ textAlign: 'center' }}>
                                <th colSpan={2}>پەیمانگە</th>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی داهات</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.institute_total_income : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی خەرجی</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.institute_total_expense : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی قەرزی ماوە</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.institute_loan_expense : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>{totalreport.institute_total_income > totalreport.institute_total_expense ? "کۆی ماوە" : "کۆی کورت هێنان"}</th>
                                <td>{numberbeautifuly(Math.abs(totalreport != 0 ? totalreport.institute_total_income - (totalreport.institute_total_expense) : 0))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                    <table >
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            <tr style={{ textAlign: 'center' }}>
                                <th colSpan={2}>خوێندنگە</th>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی داهات</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.school_total_income : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی خەرجی</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.school_total_expense : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی قەرزی ماوە</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.school_loan_expense : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>{totalreport.school_total_income > totalreport.school_total_expense ? "کۆی ماوە" : "کۆی کورت هێنان"}</th>
                                <td>{numberbeautifuly(Math.abs(totalreport != 0 ? totalreport.school_total_income - (totalreport.school_total_expense) : 0))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                    <table >
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            <tr style={{ textAlign: 'center' }}>
                                <th colSpan={2}>باخچەی منداڵان</th>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی داهات</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.kindergarten_total_income : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی خەرجی</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.kindergarten_total_expense : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی قەرزی ماوە</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.kindergarten_loan_expense : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>{totalreport.kindergarten_total_income > totalreport.kindergarten_total_expense ? "کۆی ماوە" : "کۆی کورت هێنان"}</th>
                                <td>{numberbeautifuly(Math.abs(totalreport != 0 ? totalreport.kindergarten_total_income - (totalreport.kindergarten_total_expense) : 0))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            <tr style={{ textAlign: 'center' }}>
                                <th colSpan={2}>دایەنگە</th>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی داهات</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.nursery_total_income : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی خەرجی</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.nursery_total_expense : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی قەرزی ماوە</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.nursery_loan_expense : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>{totalreport.nursery_total_income > totalreport.nursery_total_expense ? "کۆی ماوە" : "کۆی کورت هێنان"}</th>
                                <td>{numberbeautifuly(Math.abs(totalreport != 0 ? totalreport.nursery_total_income - (totalreport.nursery_total_expense) : 0))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                    <table >
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            <tr style={{ textAlign: 'center' }}>
                                <th colSpan={2}>کەریمی عەلەکە</th>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی داهات</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.boss_total_income : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی خەرجی</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.boss_total_expense : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>{totalreport.boss_total_income > totalreport.boss_total_expense ? "کۆی ماوە" : "کۆی کورت هێنان"}</th>
                                <td>{numberbeautifuly(Math.abs(totalreport != 0 ? totalreport.boss_total_income - totalreport.boss_total_expense : 0))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                    <table >
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            <tr style={{ textAlign: 'center' }}>
                                <th colSpan={2}>ڕادیۆ</th>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی داهات</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.radio_total_income : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی خەرجی</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.radio_total_expense : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>{totalreport.radio_total_income > totalreport.radio_total_expense ? "کۆی ماوە" : "کۆی کورت هێنان"}</th>
                                <td>{numberbeautifuly(Math.abs(totalreport != 0 ? totalreport.radio_total_income - totalreport.radio_total_expense : 0))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                    <table >
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            <tr style={{ textAlign: 'center' }}>
                                <th colSpan={2}>پڕۆژە</th>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی داهات</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.project_total_income : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی خەرجی</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.project_total_expense : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>{totalreport.project_total_income > totalreport.project_total_expense ? "کۆی ماوە" : "کۆی کورت هێنان"}</th>
                                <td>{numberbeautifuly(Math.abs(totalreport != 0 ? totalreport.project_total_income - totalreport.project_total_expense : 0))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            <tr style={{ textAlign: 'center' }}>
                                <th colSpan={2}>کۆمپانیا</th>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی داهات</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.company_total_income : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>کۆی خەرجی</th>
                                <td>{numberbeautifuly(totalreport != 0 ? totalreport.company_total_expense : 0)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>{totalreport.company_total_income > totalreport.company_total_expense ? "کۆی ماوە" : "کۆی کورت هێنان"}</th>
                                <td>{numberbeautifuly(Math.abs(totalreport != 0 ? totalreport.company_total_income - totalreport.company_total_expense : 0))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                    <table >
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            <tr style={{ textAlign: 'center' }}>
                                <th colSpan={2}>تێکڕا</th>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>{(totalreport.nursery_total_income - (totalreport.nursery_total_expense)) + (totalreport.kindergarten_total_income - (totalreport.kindergarten_total_expense)) + (totalreport.school_total_income - (totalreport.school_total_expense)) + (totalreport.institute_total_income - (totalreport.institute_total_expense)) + (totalreport.boss_total_income - totalreport.boss_total_expense) + (totalreport.radio_total_income - totalreport.radio_total_expense) + (totalreport.project_total_income - totalreport.project_total_expense) + (totalreport.company_total_income - totalreport.company_total_expense) > 0 ? "کۆی ماوە" : "کۆی کورت هێنان"}</th>
                                <td>{numberbeautifuly(Math.abs(totalreport != 0 ? (totalreport.nursery_total_income - (totalreport.nursery_total_expense)) + (totalreport.kindergarten_total_income - (totalreport.kindergarten_total_expense)) + (totalreport.school_total_income - (totalreport.school_total_expense)) + (totalreport.institute_total_income - (totalreport.institute_total_expense)) + (totalreport.boss_total_income - totalreport.boss_total_expense) + (totalreport.radio_total_income - totalreport.radio_total_expense) + (totalreport.project_total_income - totalreport.project_total_expense) + (totalreport.company_total_income - totalreport.company_total_expense) : 0))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <div style={{ width: '100%', marginTop: '5vh', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%' }}>
                        <span>سارا سعدی عمر<br />
                            ژمێریار</span>
                    </div>
                    <div style={{ width: '50%', display: 'flex', justifyContent: "flex-end" }}>
                        <span>ئاڤان ئەحمەد حەمە<br />
                            بەڕێوەبەر</span>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Reportdetailprint