import { useaxios } from "../../../../utils/useaxios";
export default (employ_name, employ_phone1, employ_job, lesson_part, employ_email, employ_salary, employ_agrement_amount, employ_service_amount, employ_certificate, employ_note, department_id, employ_id) => recaller => setrecaller => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    useaxios('employ/update.php', { employ_name, employ_phone1, employ_job, lesson_part, employ_email, employ_salary, employ_agrement_amount, employ_service_amount, employ_certificate, employ_note, department_id, employ_id }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            setrecaller(recaller + 1);
        } else {
            // clear();
        }
    });
}