import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { LoginContext } from '../../../home/logincontext';
import { Reportcontext } from './reportcontext';
import numberbeautifuly from '../../../utils/numberbeautify';

const Reportdetailmodal = props => {
    const { getreport, totalreport, settotalreport, showreport, setshowreport } = useContext(Reportcontext);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr style={{ textAlign: 'center' }}>
                                    <th colSpan={2}>پەیمانگە</th>
                                </tr>
                                <tr>
                                    <th>کۆی داهات</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.institute_total_income : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی خەرجی</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.institute_total_expense : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی قەرزی ماوە</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.institute_loan_expense : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>باڵانس</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.institute_total_income - (totalreport.institute_total_expense) : 0)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr style={{ textAlign: 'center' }}>
                                    <th colSpan={2}>خوێندنگە</th>
                                </tr>
                                <tr>
                                    <th>کۆی داهات</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.school_total_income : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی خەرجی</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.school_total_expense : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی قەرزی ماوە</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.school_loan_expense : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>باڵانس</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.school_total_income - (totalreport.school_total_expense) : 0)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr style={{ textAlign: 'center' }}>
                                    <th colSpan={2}>باخچەی منداڵان</th>
                                </tr>
                                <tr>
                                    <th>کۆی داهات</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.kindergarten_total_income : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی خەرجی</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.kindergarten_total_expense : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی قەرزی ماوە</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.kindergarten_loan_expense : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>باڵانس</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.kindergarten_total_income - (totalreport.kindergarten_total_expense) : 0)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr style={{ textAlign: 'center' }}>
                                    <th colSpan={2}>دایەنگە</th>
                                </tr>
                                <tr>
                                    <th>کۆی داهات</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.nursery_total_income : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی خەرجی</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.nursery_total_expense : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی قەرزی ماوە</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.nursery_loan_expense : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>باڵانس</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.nursery_total_income - (totalreport.nursery_total_expense) : 0)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr style={{ textAlign: 'center' }}>
                                    <th colSpan={2}>کەریمی عەلەکە</th>
                                </tr>
                                <tr>
                                    <th>کۆی داهات</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.boss_total_income : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی خەرجی</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.boss_total_expense : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>باڵانس</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.boss_total_income - totalreport.boss_total_expense : 0)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr style={{ textAlign: 'center' }}>
                                    <th colSpan={2}>ڕادیۆ</th>
                                </tr>
                                <tr>
                                    <th>کۆی داهات</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.radio_total_income : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی خەرجی</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.radio_total_expense : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>باڵانس</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.radio_total_income - totalreport.radio_total_expense : 0)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr style={{ textAlign: 'center' }}>
                                    <th colSpan={2}>پڕۆژە</th>
                                </tr>
                                <tr>
                                    <th>کۆی داهات</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.project_total_income : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی خەرجی</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.project_total_expense : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>باڵانس</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.project_total_income - totalreport.project_total_expense : 0)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr style={{ textAlign: 'center' }}>
                                    <th colSpan={2}>کۆمپانیا</th>
                                </tr>
                                <tr>
                                    <th>کۆی داهات</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.company_total_income : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی خەرجی</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.company_total_expense : 0)} IQD</td>
                                </tr>
                                <tr>
                                    <th>باڵانس</th>
                                    <td>{numberbeautifuly(totalreport != 0 ? totalreport.company_total_income - totalreport.company_total_expense : 0)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => {
                        props.setshow(false);
                        props.printit();
                    }}>
                        پرینت
                    </Button>
                    <Button variant="primary" onClick={() => {
                        props.setshow(false);
                    }}>
                        داخستن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Reportdetailmodal