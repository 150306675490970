import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import numberbeautifuly from '../../../utils/numberbeautify';
import { Summarycontext } from './summarycontext';
import Printsummary from './printsummary';

const Summary = () => {
    const { getsummary, totalsummary, settotalsummary, showsummary, setshowsummary } = useContext(Summarycontext);
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const [from, setfrom] = useState(today);
    const [to, setto] = useState(today);
    const [show, setshow] = useState(false);
    const [show1, setshow1] = useState(false);
    return (
        <div>
            <Printsummary show={show} setshow={setshow} from={from} to={to} />
            <Container className='print_hide'>
                <Row className='mt-3'>
                    <Col lg={5} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>لە بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="لە بەرواری" value={from} onChange={(val) => setfrom(val.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col lg={5} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>بۆ بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="بۆ بەرواری" value={to} onChange={(val) => setto(val.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col lg={1} className="mt-5"
                        onClick={() => {
                            getsummary(from, to);
                        }}
                    >
                        <Button >ڕاکێشان</Button>
                    </Col>
                </Row>
                {showsummary ?
                    <Row className='mt-5'>
                        <center><span>پوختەی حساباتی مارگرێت لە بەرواری {from} بۆ  {to}</span></center>
                        <center><span>داهات</span></center>
                        <Row className='justify-content-md-center mt-4'>
                            <table>
                                <thead style={{ border: '2px solid', textAlign: 'center' }}>
                                    <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                        <th>داهات</th>
                                        <th>بڕی پارە</th>
                                    </tr>
                                </thead>
                                <tbody style={{ border: '2px solid', textAlign: 'center' }}>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>داهاتی پەیمانگە</td>
                                        <td>{numberbeautifuly(totalsummary.institute_total_income)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>داهاتی خوێندنگە</td>
                                        <td>{numberbeautifuly(totalsummary.school_total_income)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>داهاتی باخچەی مناڵان</td>
                                        <td>{numberbeautifuly(totalsummary.kindergarten_total_income)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>داهاتی دایەنگە</td>
                                        <td>{numberbeautifuly(totalsummary.nursery_total_income)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>داهاتی هاوکاریەکانی کەریمی عەلەکە</td>
                                        <td>{numberbeautifuly(totalsummary.boss_total_income)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>داهاتی ڕادیۆ</td>
                                        <td>{numberbeautifuly(totalsummary.radio_total_income)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>داهاتی پڕۆژە</td>
                                        <td>{numberbeautifuly(totalsummary.project_total_income)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>داهاتی کۆمپانیا</td>
                                        <td>{numberbeautifuly(totalsummary.company_total_income)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>کۆی داهات</td>
                                        <td>{numberbeautifuly(totalsummary.institute_total_income + totalsummary.school_total_income + totalsummary.kindergarten_total_income + totalsummary.nursery_total_income + totalsummary.boss_total_income + totalsummary.radio_total_income + totalsummary.project_total_income + totalsummary.company_total_income)} IQD</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Row>
                        <center><span>خەرجی</span></center>
                        <Row className='justify-content-md-center mt-4'>
                            <table>
                                <thead style={{ border: '2px solid', textAlign: 'center' }}>
                                    <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                        <th>خەرجی</th>
                                        <th>بڕی پارە</th>
                                    </tr>
                                </thead>
                                <tbody style={{ border: '2px solid', textAlign: 'center' }}>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>خەرجی پەیمانگە</td>
                                        <td>{numberbeautifuly(totalsummary.institute_total_expense)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>خەرجی خوێندنگە</td>
                                        <td>{numberbeautifuly(totalsummary.school_total_expense)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>خەرجی باخچەی مناڵان</td>
                                        <td>{numberbeautifuly(totalsummary.kindergarten_total_expense)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>خەرجی دایەنگە</td>
                                        <td>{numberbeautifuly(totalsummary.nursery_total_expense)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>خەرجی و هاوکاریەکانی کەریمی عەلەکە</td>
                                        <td>{numberbeautifuly(totalsummary.boss_total_expense)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>خەرجی ڕادیۆ</td>
                                        <td>{numberbeautifuly(totalsummary.radio_total_expense)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>خەرجی پڕۆژە</td>
                                        <td>{numberbeautifuly(totalsummary.project_total_expense)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>خەرجی کۆمپانیا</td>
                                        <td>{numberbeautifuly(totalsummary.company_total_expense)} IQD</td>
                                    </tr>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>کۆی خەرجی</td>
                                        <td>{numberbeautifuly(totalsummary.institute_total_expense + totalsummary.school_total_expense + totalsummary.kindergarten_total_expense + totalsummary.nursery_total_expense + totalsummary.boss_total_expense + totalsummary.radio_total_expense + totalsummary.project_total_expense + totalsummary.company_total_expense)} IQD</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Row>
                        <center><span>پوختە</span></center>
                        <Row className='justify-content-md-center mt-4'>
                            <table>
                                <tbody style={{ border: '2px solid', textAlign: 'center' }}>
                                    <tr style={{ border: '2px solid' }}>
                                        <td>پوختەی داهات</td>
                                        <td>{numberbeautifuly((totalsummary.institute_total_income + totalsummary.school_total_income + totalsummary.kindergarten_total_income + totalsummary.nursery_total_income + totalsummary.boss_total_income + totalsummary.radio_total_income + totalsummary.project_total_income + totalsummary.company_total_income) - (totalsummary.institute_total_expense + totalsummary.school_total_expense + totalsummary.kindergarten_total_expense + totalsummary.nursery_total_expense + totalsummary.boss_total_expense + totalsummary.radio_total_expense + totalsummary.project_total_expense + totalsummary.company_total_expense))} IQD</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Row>
                        <Row className='py-5'>
                            <Col lg={4}>
                                <Button variant="dark" onClick={() => {
                                    setshow(true);
                                    window.print();
                                }}>
                                    پرینت
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                    : <div></div>}
            </Container>
        </div>
    )
}

export default Summary