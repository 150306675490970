import React, { useContext, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Logo from '../../../margretlogo.png';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import { GlobalContext } from '../../context/provider';
import moment from "moment";
import '../css/onprinttoggle.css';
import numberbeautifuly from '../../../utils/numberbeautify';

const Balance_report_detail_print = props => {
    const { reportState, reportDispatch } = useContext(GlobalContext);
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return (
        <div className='print_show'>
            <Container>
                <Row className='justify-content-md-center mt-3'>
                    <Image src={Logo} style={{ width: 110, height: 100 }} />
                    <center><span>لیستی تێکڕای هەژماری دایەنگە لە بەرواری {moment(props.from).format('YYYY-MM-DD')} بۆ بەرواری {moment(props.to).format('YYYY-MM-DD')}</span></center>
                </Row>
                <Row className='justify-content-md-center mt-3'>
                    <table>
                        <thead style={{ border: '2px solid', textAlign: 'center' }}>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th colSpan={2}>تێکڕای داهات</th>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>جۆری داهات</th>
                                <th>بڕی داهات</th>
                            </tr>
                        </thead>
                        <tbody style={{ border: '2px solid', textAlign: 'center' }}>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <td>سزا</td>
                                <td>{numberbeautifuly(reportState.income_punish)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <td>قیست</td>
                                <td>{numberbeautifuly(reportState.income_scissors)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <td>داهاتی تر</td>
                                <td>{numberbeautifuly(reportState.income_other)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <td>کۆی داهات</td>
                                <td>{numberbeautifuly(reportState.total_income)} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <Row className='justify-content-md-center mt-4'>
                    <table>
                        <thead style={{ border: '2px solid', textAlign: 'center' }}>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th colSpan={2}>تێکڕای خەرجی</th>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>جۆری خەرجی</th>
                                <th>بڕی خەرجی</th>
                            </tr>
                        </thead>
                        <tbody style={{ border: '2px solid', textAlign: 'center' }}>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <td>موچە</td>
                                <td>{numberbeautifuly(reportState.expense_salary)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <td>خەرجی ڕۆژانە</td>
                                <td>{numberbeautifuly(reportState.daily_expense)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <td>خەرجی تر</td>
                                <td>{numberbeautifuly(reportState.expense_other)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <td>کۆی خەرجی</td>
                                <td>{numberbeautifuly(reportState.total_expense)} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <Row className='justify-content-md-center mt-4'>
                    <table>
                        <thead style={{ border: '2px solid', textAlign: 'center' }}>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th colSpan={2}>
                                    {reportState.balance > 0 ? "کۆی ماوە" : "کۆی کورتهێنان"}
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{ border: '2px solid', textAlign: 'center' }}>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <td>{numberbeautifuly(Math.abs(reportState.balance))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <div style={{ width: '100%', marginTop: '5vh', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%' }}>
                        <span>سارا سعدی عمر<br />
                            ژمێریار</span>
                    </div>
                    <div style={{ width: '50%', display: 'flex', justifyContent: "flex-end" }}>
                        <span>ئاڤان ئەحمەد حەمە<br />
                            بەڕێوەبەر</span>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Balance_report_detail_print