import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Reportcontext } from './reportcontext';
import Reportdetailmodal from './reportdetailmodal';
import Reportdetailprint from './reportdetailprint';
import '../css/onprinttoggle.css';
import numberbeautifuly from '../../../utils/numberbeautify';

const Report = () => {
    const { getreport, totalreport, settotalreport, showreport, setshowreport } = useContext(Reportcontext);
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const [from, setfrom] = useState(today);
    const [to, setto] = useState(today);
    const [show, setshow] = useState(false);
    const [show1, setshow1] = useState(false);

    const printit = async () => {
        setshow(false);
        setshow(false);
        setTimeout(() => { window.print(); }, 250);
    }
    return (
        <div dir="rtl">
            <Reportdetailmodal show={show} setshow={setshow} printit={printit} />
            <Reportdetailprint from={from} to={to} />
            <Container className='print_hide'>
                <Row className='mt-3'>
                    <Col lg={5} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>لە بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="لە بەرواری" value={from} onChange={(val) => setfrom(val.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col lg={5} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>بۆ بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="بۆ بەرواری" value={to} onChange={(val) => setto(val.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col lg={1} className="mt-5"
                        onClick={() => {
                            getreport(from, to);
                        }}
                    >
                        <Button >ڕاکێشان</Button>
                    </Col>
                </Row>
                {showreport ?
                    <Row>
                        <Row className='mt-5'>
                            <Col lg={4}>
                                <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }} onClick={() => {

                                }}>
                                    <Card.Body>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <div>پەیمانگە</div>
                                                <div style={{ fontSize: 13 }}>--------------------------------------------------------------------------</div>
                                                <div style={{ fontSize: 13 }}>کۆی داهات : {numberbeautifuly(totalreport != 0 ? totalreport.institute_total_income : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>کۆی خەرجی : {numberbeautifuly(totalreport != 0 ? totalreport.institute_total_expense : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>کۆی قەرزی ماوە : {numberbeautifuly(totalreport != 0 ? totalreport.institute_loan_expense : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>باڵانس : {numberbeautifuly(totalreport != 0 ? totalreport.institute_total_income - (totalreport.institute_total_expense) : 0)} IQD</div>
                                                {/* <div style={{ fontSize: 13 }}></div> */}
                                            </div>
                                            {/* <BsFillCalendarCheckFill size={23} color="red" /> */}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }} onClick={() => {

                                }}>
                                    <Card.Body>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <div>خوێندنگە</div>
                                                <div style={{ fontSize: 13 }}>--------------------------------------------------------------------------</div>
                                                <div style={{ fontSize: 13 }}>کۆی داهات : {numberbeautifuly(totalreport != 0 ? totalreport.school_total_income : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>کۆی خەرجی : {numberbeautifuly(totalreport != 0 ? totalreport.school_total_expense : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>کۆی قەرزی ماوە : {numberbeautifuly(totalreport != 0 ? totalreport.school_loan_expense : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>باڵانس : {numberbeautifuly(totalreport != 0 ? totalreport.school_total_income - (totalreport.school_total_expense) : 0)} IQD</div>
                                                {/* <div style={{ fontSize: 13 }}></div> */}
                                            </div>
                                            {/* <BsFillCalendarCheckFill size={23} color="red" /> */}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }} onClick={() => {

                                }}>
                                    <Card.Body>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <div>باخچەی منداڵان</div>
                                                <div style={{ fontSize: 13 }}>--------------------------------------------------------------------------</div>
                                                <div style={{ fontSize: 13 }}>کۆی داهات : {numberbeautifuly(totalreport != 0 ? totalreport.kindergarten_total_income : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>کۆی خەرجی : {numberbeautifuly(totalreport != 0 ? totalreport.kindergarten_total_expense : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>کۆی قەرزی ماوە : {numberbeautifuly(totalreport != 0 ? totalreport.kindergarten_loan_expense : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>باڵانس : {numberbeautifuly(totalreport != 0 ? totalreport.kindergarten_total_income - (totalreport.kindergarten_total_expense) : 0)} IQD</div>
                                                {/* <div style={{ fontSize: 13 }}></div> */}
                                            </div>
                                            {/* <BsFillCalendarCheckFill size={23} color="red" /> */}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col lg={4}>
                                <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }} onClick={() => {

                                }}>
                                    <Card.Body>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <div>دایەنگە</div>
                                                <div style={{ fontSize: 13 }}>--------------------------------------------------------------------------</div>
                                                <div style={{ fontSize: 13 }}>کۆی داهات : {numberbeautifuly(totalreport != 0 ? totalreport.nursery_total_income : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>کۆی خەرجی : {numberbeautifuly(totalreport != 0 ? totalreport.nursery_total_expense : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>کۆی قەرزی ماوە : {numberbeautifuly(totalreport != 0 ? totalreport.nursery_loan_expense : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>باڵانس : {numberbeautifuly(totalreport != 0 ? totalreport.nursery_total_income - (totalreport.nursery_total_expense) : 0)} IQD</div>
                                                {/* <div style={{ fontSize: 13 }}></div> */}
                                            </div>
                                            {/* <BsFillCalendarCheckFill size={23} color="red" /> */}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }} onClick={() => {

                                }}>
                                    <Card.Body>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <div>کەریمی عەلەکە</div>
                                                <div style={{ fontSize: 13 }}>--------------------------------------------------------------------------</div>
                                                <div style={{ fontSize: 13 }}>-</div>
                                                <div style={{ fontSize: 13 }}>کۆی داهات : {numberbeautifuly(totalreport != 0 ? totalreport.boss_total_income : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>کۆی خەرجی : {numberbeautifuly(totalreport != 0 ? totalreport.boss_total_expense : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>باڵانس : {numberbeautifuly(totalreport != 0 ? totalreport.boss_total_income - totalreport.boss_total_expense : 0)} IQD</div>
                                                {/* <div style={{ fontSize: 13 }}></div> */}
                                            </div>
                                            {/* <BsFillCalendarCheckFill size={23} color="red" /> */}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }} onClick={() => {

                                }}>
                                    <Card.Body>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <div>ڕادیۆ</div>
                                                <div style={{ fontSize: 13 }}>--------------------------------------------------------------------------</div>
                                                <div style={{ fontSize: 13 }}>-</div>
                                                <div style={{ fontSize: 13 }}>کۆی داهات : {numberbeautifuly(totalreport != 0 ? totalreport.radio_total_income : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>کۆی خەرجی : {numberbeautifuly(totalreport != 0 ? totalreport.radio_total_expense : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>باڵانس : {numberbeautifuly(totalreport != 0 ? totalreport.radio_total_income - totalreport.radio_total_expense : 0)} IQD</div>
                                                {/* <div style={{ fontSize: 13 }}></div> */}
                                            </div>
                                            {/* <BsFillCalendarCheckFill size={23} color="red" /> */}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col lg={4}>
                                <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }} onClick={() => {

                                }}>
                                    <Card.Body>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <div>پڕۆژە</div>
                                                <div style={{ fontSize: 13 }}>--------------------------------------------------------------------------</div>
                                                <div style={{ fontSize: 13 }}>-</div>
                                                <div style={{ fontSize: 13 }}>کۆی داهات : {numberbeautifuly(totalreport != 0 ? totalreport.project_total_income : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>کۆی خەرجی : {numberbeautifuly(totalreport != 0 ? totalreport.project_total_expense : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>باڵانس : {numberbeautifuly(totalreport != 0 ? totalreport.project_total_income - totalreport.project_total_expense : 0)} IQD</div>
                                                {/* <div style={{ fontSize: 13 }}></div> */}
                                            </div>
                                            {/* <BsFillCalendarCheckFill size={23} color="red" /> */}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }} onClick={() => {

                                }}>
                                    <Card.Body>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <div>کۆمپانیا</div>
                                                <div style={{ fontSize: 13 }}>--------------------------------------------------------------------------</div>
                                                <div style={{ fontSize: 13 }}>-</div>
                                                <div style={{ fontSize: 13 }}>کۆی داهات : {numberbeautifuly(totalreport != 0 ? totalreport.company_total_income : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>کۆی خەرجی : {numberbeautifuly(totalreport != 0 ? totalreport.company_total_expense : 0)} IQD</div>
                                                <div style={{ fontSize: 13 }}>باڵانس : {numberbeautifuly(totalreport != 0 ? totalreport.company_total_income - totalreport.company_total_expense : 0)} IQD</div>
                                                {/* <div style={{ fontSize: 13 }}></div> */}
                                            </div>
                                            {/* <BsFillCalendarCheckFill size={23} color="red" /> */}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }} onClick={() => {
                                    setshow(true);
                                }}>
                                    <Card.Body>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div>
                                                <div>{(totalreport.nursery_total_income - (totalreport.nursery_total_expense)) + (totalreport.kindergarten_total_income - (totalreport.kindergarten_total_expense)) + (totalreport.school_total_income - (totalreport.school_total_expense)) + (totalreport.institute_total_income - (totalreport.institute_total_expense)) + (totalreport.boss_total_income - totalreport.boss_total_expense) + (totalreport.radio_total_income - totalreport.radio_total_expense) + (totalreport.project_total_income - totalreport.project_total_expense) + (totalreport.company_total_income - totalreport.company_total_expense) > 0 ? "کۆی ماوە" : "کۆی کورت هێنان"}</div>
                                                <div style={{ fontSize: 13 }}>--------------------------------------------------------------------------</div>
                                                <div style={{ fontSize: 13 }}>-</div>
                                                <div style={{ fontSize: 13 }}>-</div>
                                                <div style={{ fontSize: 13 }}>-</div>
                                                <div style={{ fontSize: 13 }}>باڵانس : {numberbeautifuly(totalreport != 0 ? (totalreport.nursery_total_income - (totalreport.nursery_total_expense)) + (totalreport.kindergarten_total_income - (totalreport.kindergarten_total_expense)) + (totalreport.school_total_income - (totalreport.school_total_expense)) + (totalreport.institute_total_income - (totalreport.institute_total_expense)) + (totalreport.boss_total_income - totalreport.boss_total_expense) + (totalreport.radio_total_income - totalreport.radio_total_expense) + (totalreport.project_total_income - totalreport.project_total_expense) + (totalreport.company_total_income - totalreport.company_total_expense) : 0)} IQD</div>
                                                {/* <div style={{ fontSize: 13 }}></div> */}
                                            </div>
                                            {/* <BsFillCalendarCheckFill size={23} color="red" /> */}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Row> : <div></div>}
            </Container>
        </div>
    )
}

export default Report