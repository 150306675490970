import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { LoginContext } from '../../../home/logincontext';
import updateaction from '../../context/action/expense/updateaction';
import deleteaction from '../../context/action/expense/deleteaction';

const Editexpensemodal = props => {
    const { setloading } = useContext(LoginContext);

    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);

    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ناوی کەس و مەبەستی خەرجی</Form.Label>
                                <Form.Control type="text" placeholder="ناوی کەس و مەبەستی خەرجی" value={props.name} onChange={(val) => props.setname(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error ? "red" : null }}>بڕ</Form.Label>
                                <Form.Control style={{ borderColor: error ? "red" : null }} type="number" placeholder="بڕ" value={props.amount} onChange={(val) => props.setamount(val.target.value)} onFocus={() => seterror(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>شوێن و جۆری خەرجی</Form.Label>
                                <Form.Control type="text" placeholder="شوێن و جۆری خەرجی" value={props.type} onChange={(val) => props.settype(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error1 ? "red" : null }}>بەروار</Form.Label>
                                <Form.Control style={{ borderColor: error1 ? "red" : null }} type="date" placeholder="بەروار" value={props.date} onChange={(val) => props.setdate(val.target.value)} onFocus={() => seterror1(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>تێبینی</Form.Label>
                                <Form.Control type="text" placeholder="تێبینی" value={props.note} onChange={(val) => props.setnote(val.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        if (window.confirm("ئاگاداری ڕەشکردنەوە . دڵنیای ؟") == true) {
                            deleteaction(props.expense_id)(setloading);
                            props.setshow(false);
                        };
                    }}>
                        ڕەشکردنەوە
                    </Button>
                    <Button variant="primary" onClick={() => {
                        if (props.amount == '' || props.amount < 0 || !props.amount) {
                            seterror(true);
                            return false;
                        }
                        if (props.date == '' || !props.date) {
                            seterror1(true);
                            return false;
                        }
                        updateaction(props.name, props.amount, props.type, props.date, props.note, props.expense_id)(setloading);
                        props.setshow(false);
                    }}>
                        گۆڕانکاری
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Editexpensemodal