import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Limage from '../margretlogo.png';
import Alert from 'react-bootstrap/Alert'
import { LoginContext } from './logincontext';

const Login = () => {
    const { check_login } = useContext(LoginContext);

    const [username, setusername] = useState();
    const [password, setpassword] = useState();
    const [show, setshow] = useState(false);
    return (
        <Container fluid >
            <Row className="justify-content-md-center my-5">
                <Col xs lg="3">
                    <Image src={Limage} fluid />
                </Col>
            </Row>
            {/* <Form> */}
            <Row className="justify-content-md-center mt-4">
                <Col lg={6}>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="name" placeholder="Enter Username" value={username} onChange={(val) => setusername(val.target.value)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="justify-content-md-center mt-4">
                <Col lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" value={password} onChange={(val) => setpassword(val.target.value)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="justify-content-md-center mt-3">
                <Col lg={6}>
                    <Button size="lg" variant="outline-info" onClick={() => {
                        setshow(false);
                        check_login(username, password, (callback) => {
                            if (callback.state == "wrong") {
                                setshow(true);
                            }
                        });
                    }}>Login</Button>
                </Col>
            </Row>
            <br />
            <Row className="justify-content-md-center mt-3">
                <Col lg={6}>
                    <Alert show={show} variant="danger" className="mt-2">
                        <center><Alert.Heading>Username Or Password Incorrect</Alert.Heading></center>
                    </Alert>
                </Col>
            </Row>
        </Container>
    )
}

export default Login