import { useaxios } from "../../../../utils/useaxios";
export default (employ_id, salary, employ_expense_date, note, department_id) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    setloading(true);
    useaxios('employ_expense/create.php', { employ_expense_type: 'reward', employ_expense_amount: salary, employ_expense_note: note, employ_expense_date, employ_id, department_id }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}