import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import report from '../../context/action/report/report';
import { GlobalContext } from '../../context/provider';
import Income_report_detail_modal from './income_report_detail_modal';
import Expense_report_detail_modal from './expense_report_detail_modal';
import '../css/onprinttoggle.css';
import Expense_report_detail_print from './expense_report_detail_print';
import Income_report_detail_print from './income_report_detail_print';
import Mainreport_detail_modal from './mainreport_detail_modal';
import Mainreport_detail_print from './mainreport_detail_print';
import numberbeautifuly from '../../../utils/numberbeautify';

const Mainreport = () => {
    const { reportState, reportDispatch } = useContext(GlobalContext);
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const [from, setfrom] = useState(today);
    const [to, setto] = useState(today);
    const [show, setshow] = useState(false);
    const [show1, setshow1] = useState(false);
    const [show2, setshow2] = useState(false);
    const [reporttype, setreporttype] = useState('');

    const printit = async () => {
        setshow(false);
        setshow1(false);
        setTimeout(() => { window.print(); }, 250);
    }
    return (
        <div dir="rtl">
            {reporttype == 'expense' ? <Expense_report_detail_print from={from} to={to} /> : reporttype == 'income' ? <Income_report_detail_print from={from} to={to} /> : reporttype == 'total' ? <Mainreport_detail_print from={from} to={to} /> : <div />}
            <Container className='print_hide'>
                <Income_report_detail_modal show={show} setshow={setshow} printit={printit} setreporttype={setreporttype} />
                <Expense_report_detail_modal show={show1} setshow={setshow1} printit={printit} setreporttype={setreporttype} />
                <Mainreport_detail_modal show={show2} setshow={setshow2} printit={printit} setreporttype={setreporttype} />
                <Row className='mt-3'>
                    <Col lg={5} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>لە بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="لە بەرواری" value={from} onChange={(val) => setfrom(val.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col lg={5} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>بۆ بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="بۆ بەرواری" value={to} onChange={(val) => setto(val.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col lg={1} className="mt-5"
                        onClick={() => {
                            report(from, to)(reportDispatch);
                        }}
                    >
                        <Button >ڕاکێشان</Button>
                    </Col>
                </Row>
                {reportState.isshow ? <Row className='mt-5'>
                    <Col lg={4}>
                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }} onClick={() => {
                            setshow(true);
                        }}>
                            <Card.Body>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <div>تێکڕای هاتوو</div>
                                        <div style={{ fontSize: 13 }}>--------------------------------------------------------------------------</div>
                                        <div style={{ fontSize: 13 }}>{numberbeautifuly(reportState.total_income)} IQD</div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }} onClick={() => {
                            setshow1(true);
                        }}>
                            <Card.Body>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <div>تێکڕای خەرجکراوو</div>
                                        <div style={{ fontSize: 13 }}>--------------------------------------------------------------------------</div>
                                        <div style={{ fontSize: 13 }}>{numberbeautifuly(reportState.total_expense)} IQD</div>
                                    </div>
                                    {/* <BsFillCalendarCheckFill size={23} color="red" /> */}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }} onClick={() => {
                            setshow2(true);
                        }}>
                            <Card.Body>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <div>{reportState.total > 0 ? "کۆی ماوە" : "کۆی کورت هێنان"}</div>
                                        <div style={{ fontSize: 13 }}>--------------------------------------------------------------------------</div>
                                        <div style={{ fontSize: 13 }}>{numberbeautifuly(reportState.total)} IQD</div>
                                    </div>
                                    {/* <BsFillCalendarCheckFill size={23} color="red" /> */}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row> : <div />}
                {/* {reportState.isshow ? <Row className='justify-content-md-center mt-5'>
                </Row> : <div />} */}
            </Container>
        </div>
    )
}

export default Mainreport