import { useaxios } from "../../../../utils/useaxios";
export default (student_id, amount, student_income_date, note) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    setloading(true);
    useaxios('student_income/create.php', { student_income_type: 'scissors', student_income_amount: amount, student_income_note: note, student_income_date, student_id, department_id: 2 }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}