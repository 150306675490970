import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import updateaction from '../../context/action/student/updateaction';
import deleteaction from '../../context/action/student/deleteaction';


const Editstudentmodal = props => {
    const { initState } = useContext(GlobalContext);
    const { loading, setloading } = useContext(LoginContext);

    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);
    const [error2, seterror2] = useState(false);
    const [error3, seterror3] = useState(false);
    const [error4, seterror4] = useState(false);
    const [error5, seterror5] = useState(false);
    const [error6, seterror6] = useState(false);
    const [error7, seterror7] = useState(false);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    {/* {JSON.stringify(initState.class[0].class_id)} */}
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error ? "red" : null }}>ناو</Form.Label>
                                <Form.Control style={{ borderColor: error ? "red" : null }} type="text" placeholder="ناو" value={props.student_name} onChange={(val) => props.setstudent_name(val.target.value)} onFocus={() => seterror(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ڕەگەز</Form.Label>
                                <Form.Select onChange={(val) => props.setstudent_gender(val.target.value)} value={props.student_gender} dir="ltr">
                                    <option value='male'>کوڕ</option>
                                    <option value='female'>کچ</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ژمارە تەلەفۆن </Form.Label>
                                <Form.Control type="number" placeholder="ژمارە تەلەفۆن " value={props.student_phone1} onChange={(val) => props.setstudent_phone1(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ساڵی خوێندن</Form.Label>
                                <Form.Control type="text" value={props.student_birthday} placeholder="ساڵی خوێندن" onChange={(val) => props.setstudent_birthday(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error6 ? "red" : null }}>کڵاس</Form.Label>
                                <Form.Select style={{ borderColor: error6 ? "red" : null }} onChange={(val) => props.setstudent_class(val.target.value)} value={props.student_class} dir="ltr" onFocus={() => seterror6(false)}>
                                    <option value={0}>کڵاسێک هەڵبژێرە</option>
                                    {initState.class.map((lis) => (
                                        <option value={lis.class_id}>{lis.class_name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error7 ? "red" : null }}>تێچوی خوێندن</Form.Label>
                                <Form.Control style={{ borderColor: error7 ? "red" : null }} type="number" placeholder="تێچوی خوێندن" value={props.student_cost} onChange={(val) => props.setstudent_cost(val.target.value)} onFocus={() => seterror7(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ژمارەی وەسڵ</Form.Label>
                                <Form.Control type="text" placeholder="ژمارەی وەسڵ" value={props.student_code} onChange={(val) => props.setstudent_code(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>تێبینی</Form.Label>
                                <Form.Control type="text" placeholder="تێبینی" value={props.student_note} onChange={(val) => props.setstudent_note(val.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        if (window.confirm("ئاگاداری ڕەشکردنەوە . دڵنیای ؟") == true) {
                            deleteaction(props.student_id)(setloading);
                            props.setshow(false);
                        }
                    }}>
                        ڕەشکردنەوە
                    </Button>
                    <Button variant="primary" onClick={() => {
                        if (props.student_name == '' || !props.student_name) {
                            seterror(true);
                            return false;
                        }
                        if (props.student_class == '' || !props.student_class || props.student_class == 0) {
                            seterror6(true);
                            return false;
                        }
                        if (props.student_cost == '' || props.student_cost < 1 || !props.student_cost) {
                            seterror7(true);
                            return false;
                        }
                        if (window.confirm("ئاگاداری گۆڕانکاری . دڵنیای ؟") == true) {
                            updateaction(props.student_name, props.student_phone1, props.student_gender, props.student_birthday, props.student_note, props.student_cost, props.student_code, props.student_class, props.student_id)(setloading);
                            props.setshow(false);
                        }
                    }}>
                        گۆڕانکاری
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Editstudentmodal