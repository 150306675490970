import { useaxios } from "../../../../utils/useaxios";
export default (name, phone1, gender, birthday, note, cost, code, classs) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setloading(true);
    useaxios('student/create.php', { student_name: name, student_gender: gender, student_birthday: birthday, student_phone1: phone1, student_note: note, student_cost: cost, student_code: code, class_id: classs, department_id: 4, student_date: today }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}