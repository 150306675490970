import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const Studentdetailmodal = props => {
    return (
        <div dir='rtl'>
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ناو</Form.Label>
                                <Form.Control type="text" placeholder="ناو" value={props.lis.student_name} disabled />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ڕەگەز</Form.Label>
                                <Form.Control type="text" placeholder="ڕەگەز" value={props.lis.student_gender == "male" ? "کوڕ" : "کچ"} disabled />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ژمارە تەلەفۆن </Form.Label>
                                <Form.Control type="text" placeholder="ژمارە تەلەفۆن " value={props.lis.student_phone1} disabled />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ساڵی خوێندن</Form.Label>
                                <Form.Control type="text" placeholder="ساڵی خوێندن" value={props.lis.student_birthday} disabled />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>کڵاس</Form.Label>
                                <Form.Control type="text" placeholder="کڵاس" value={props.lis.class_name} disabled />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>تێچوی خوێندن</Form.Label>
                                <Form.Control type="text" placeholder="تێچوی خوێندن" value={props.lis.student_cost + "IQD"} disabled />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ژمارەی وەسڵ</Form.Label>
                                <Form.Control type="text" placeholder="ژمارەی وەسڵ" value={props.lis.student_code} disabled />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>تێبینی</Form.Label>
                                <Form.Control type="text" placeholder="تێبینی" value={props.lis.student_note} disabled />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => {
                        props.setshow(false);
                    }}>
                        داخستن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Studentdetailmodal