import React, { createContext, useReducer, useEffect, useState } from "react";
import init from './action/init/initaction';
import initstate from "./initialState/initstate";
import reportstate from "./initialState/reportstate";
import Initreducer from "./reducer/initreducer";
import Reportreducer from "./reducer/reportreducer";


export const GlobalContext = createContext({});

const GlobalProvider = ({ children }) => {
    const [initState, initDispatch] = useReducer(Initreducer, initstate);
    const [reportState, reportDispatch] = useReducer(Reportreducer, reportstate);
    const [recaller, setrecaller] = useState(0);


    useEffect(() => {
        init()(initDispatch);
    }, [recaller]);
    return <GlobalContext.Provider value={{
        reportState, reportDispatch,
        initState, initDispatch,
        recaller, setrecaller,
    }}>{children}</GlobalContext.Provider>
}

export default GlobalProvider;