const Initreducer = (state, { type, payload }) => {
    switch (type) {
        case 'setinit':
            return { ...state, income: payload.income, expense: payload.expense };
        case 'setincome':
            return { ...state, income: payload };
        case 'setexpense':
            return { ...state, expense: payload };
        default:
            return state;
    }
}

export default Initreducer;