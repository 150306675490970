import { useaxios } from "../../../../utils/useaxios";
export default (date) => employDispatch => {
    useaxios('reports/salary_list_detail.php', { date }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            employDispatch({ type: 'setsalarylistdetail', payload: res.salary_detail });
        } else {
            // clear();
        }
    });
}