import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdEditNote } from "react-icons/md";
import Editcertificatemodal from './editcertificatemodal';
import numberbeautifuly from '../../../utils/numberbeautify';

const Certificatelist = () => {
    const { initState } = useContext(GlobalContext);

    const [certificatename, setcertificatename] = useState("");
    const [certificateamount, setcertificateamount] = useState(0);
    const [show, setshow] = useState(false);
    const [certificateid, setcertificateid] = useState(0);
    return (
        <div dir="rtl">
            <Container>
                <Editcertificatemodal certificatename={certificatename} setcertificatename={setcertificatename} certificateamount={certificateamount} setcertificateamount={setcertificateamount} certificateid={certificateid} setcertificateid={setcertificateid} show={show} setshow={setshow} />
                <Row className='justify-content-md-center mt-5'>
                    <Col lg={11}>
                        <Table striped bordered hover size="lg" style={{ textAlign: 'center' }} >
                            <thead>
                                <tr>
                                    <th>بڕوانامە </th>
                                    <th>بڕی کۆمیشن</th>
                                    <th>ڕێکخستن</th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: 12 }}>
                                {initState.certificate.map((lis) => (
                                    <tr>
                                        <td>{lis.certificate_name}</td>
                                        <td>{numberbeautifuly(lis.certificate_amount)} IQD</td>
                                        <td style={{ fontSize: 22 }}>
                                            <a style={{ color: '#f39c12', fontSize: 26 }} href="#" onClick={() => {
                                                setcertificatename(lis.certificate_name);
                                                setcertificateamount(lis.certificate_amount);
                                                setcertificateid(lis.certificate_id);
                                                setshow(true);
                                                // alert(certificatename);
                                            }}>
                                                <MdEditNote />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Certificatelist