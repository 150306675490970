import React, { useContext, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import '../css/menu.css';
import '../css/onprinttoggle.css';
import Image from 'react-bootstrap/Image';
import Logo from '../../margretlogo.png';

const Menu = () => {
    const location = useLocation();

    return (
        <div className="usr_menu print_hide">
            <br />
            <center><Image src={Logo} style={{ width: 80, height: 90 }} /></center>
            <center><h6>ڕێکخستنەکانی سیستەم</h6></center>
            <ul>
                <Link to="/setting/"><li className={location.pathname == '/setting/' ? 'aactive' : null}>بڕوانامە</li></Link>
                <Link to="/setting/systemoption"><li className={location.pathname == '/setting/systemoption' ? 'aactive' : null}>بەشە وانە</li></Link>
            </ul>
        </div>
    )
}

export default Menu