import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import createaction from '../../context/action/student/createaction';
import { LoginContext } from '../../../home/logincontext';

const Addstudentmodal = (props) => {
    const { initState } = useContext(GlobalContext);
    const { loading, setloading } = useContext(LoginContext);

    const [name, setname] = useState();
    const [phone1, setphone1] = useState();
    const [phone2, setphone2] = useState();
    const [phone3, setphone3] = useState();
    const [address, setaddress] = useState();
    const [gender, setgender] = useState('male');
    const [birthday, setbirthday] = useState();
    const [blood, setblood] = useState('A+');
    const [note, setnote] = useState();
    const [cost, setcost] = useState();
    const [code, setcode] = useState();
    const [passport, setpassport] = useState();
    const [classs, setclasss] = useState(1);

    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);
    const [error2, seterror2] = useState(false);
    const [error3, seterror3] = useState(false);
    const [error4, seterror4] = useState(false);
    const [error5, seterror5] = useState(false);
    const [error6, seterror6] = useState(false);
    const [error7, seterror7] = useState(false);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    {/* {JSON.stringify(initState.class[0].class_id)} */}
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error ? "red" : null }}>ناو</Form.Label>
                                <Form.Control style={{ borderColor: error ? "red" : null }} type="text" placeholder="ناو" value={name} onChange={(val) => setname(val.target.value)} onFocus={() => seterror(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ڕەگەز</Form.Label>
                                <Form.Select onChange={(val) => setgender(val.target.value)} dir="ltr">
                                    <option value='male'>کوڕ</option>
                                    <option value='female'>کچ</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ژمارە تەلەفۆن </Form.Label>
                                <Form.Control type="number" placeholder="ژمارە تەلەفۆن " value={phone1} onChange={(val) => setphone1(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ساڵی خوێندن</Form.Label>
                                <Form.Control type="text" value={birthday} placeholder="ساڵی خوێندن" onChange={(val) => setbirthday(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error6 ? "red" : null }}>کڵاس</Form.Label>
                                <Form.Select style={{ borderColor: error6 ? "red" : null }} onChange={(val) => setclasss(val.target.value)} value={classs} dir="ltr" onFocus={() => seterror6(false)}>
                                    <option value={0}>کڵاسێک هەڵبژێرە</option>
                                    {initState.class.map((lis) => (
                                        <option value={lis.class_id}>{lis.class_name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error7 ? "red" : null }}>تێچوی خوێندن</Form.Label>
                                <Form.Control style={{ borderColor: error7 ? "red" : null }} type="number" placeholder="تێچوی خوێندن" value={cost} onChange={(val) => setcost(val.target.value)} onFocus={() => seterror7(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ژمارەی وەسڵ</Form.Label>
                                <Form.Control type="text" placeholder="ژمارەی وەسڵ" value={code} onChange={(val) => setcode(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>تێبینی</Form.Label>
                                <Form.Control type="text" placeholder="تێبینی" value={note} onChange={(val) => setnote(val.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        if (name == '' || !name) {
                            seterror(true);
                            return false;
                        }
                        if (classs == '' || !classs || classs == 0) {
                            seterror6(true);
                            return false;
                        }
                        if (cost == '' || cost < 1 || !cost) {
                            seterror7(true);
                            return false;
                        }
                        createaction(name, phone1, gender, birthday, note, cost, code, classs)(setloading);
                        props.setshow(false);
                    }}>
                        زیادکردن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Addstudentmodal