import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdEditNote } from "react-icons/md";
import getsalarydetailaction from '../../context/action/salary/getsalarydetailaction';
import numberbeautify from '../../../utils/numberbeautify';
import moment from 'moment';
import Salarylistdetailprint from './salarylistdetailprint';
import deletesalaryaction from '../../context/action/salary/deletesalaryaction';

const Salarylistdetail = () => {
    const { employState, employDispatch } = useContext(GlobalContext);
    const [salarydate, setsalarydate] = useState();
    const [error, seterror] = useState(false);
    return (
        <div dir="rtl">
            <Container>
                <Salarylistdetailprint date={salarydate} />
                <Row className='mt-4 print_hide'>
                    <center><h3><b>وردەکاری لیستی موچە</b></h3></center>
                </Row>
                <Row className='justify-content-md-center mt-3 print_hide'>
                    <Col lg={6} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label style={{ color: error ? "red" : null }}>بەروار</Form.Label>
                            <Form.Control style={{ borderColor: error ? "red" : null }} type="date" value={salarydate} placeholder="بەرواری لیستی موچە" onChange={(val) => setsalarydate(val.target.value)} onFocus={() => seterror(false)} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mt-5">
                        <Button variant="primary" onClick={() => {
                            if (!salarydate || salarydate == "") {
                                seterror(true);
                                return false;
                            }
                            getsalarydetailaction(salarydate)(employDispatch);
                        }}>
                            ڕاکێشان
                        </Button>
                    </Col>
                </Row>
                {employState.salary_detail.length > 0 ?
                    <Row className='justify-content-md-center mt-5 print_hide'>
                        <Col lg={11}>
                            <Table striped bordered hover size="lg" style={{ textAlign: 'center' }}>
                                <thead style={{ fontsize: 10 }}>
                                    <tr>
                                        <th>ناوی بەش</th>
                                        <th>کۆی خەرجی بۆ موچە</th>
                                        <th>بەروار</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: 10 }}>
                                    {employState.salary_detail.map((lis) => (
                                        <tr>
                                            <td>{lis.department_name}</td>
                                            <td>{numberbeautify(lis.total_salary_detail)} IQD</td>
                                            <td>{moment(salarydate).format('YYYY-MM')}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan={2}>کۆی خەرجی</td>
                                        <td>{numberbeautify(employState.salary_detail.reduce((a, v) => a = Number(a) + Number(v.total_salary_detail), 0))} IQD</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col lg={11}>
                            <Button variant='dark' className='mb-3' disabled={employState.salary_detail.length > 0 ? false : true} onClick={() => { window.print() }} style={{ marginLeft: '1%' }}>چاپکردن</Button>
                            <Button variant='danger' className='mb-3' onClick={() => {
                                if (window.confirm("ئاگاداری ڕەشکردنەوەی لیستی موچە . دڵنیای ؟") == true) {
                                    // salary_listaction(salarydate)(employDispatch);
                                    deletesalaryaction(salarydate)(employDispatch);
                                }
                            }}>ڕەشکردنەوە</Button>
                        </Col>
                    </Row>
                    :
                    <div />}
            </Container>
        </div>
    )
}

export default Salarylistdetail