import React, { useContext, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Logo from '../../../margretlogo.png';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import { GlobalContext } from '../../context/provider';
import moment from "moment";
import '../css/onprinttoggle.css';
import numberbeautifuly from '../../../utils/numberbeautify';

const Chekoutprint = props => {
    const { studentState } = useContext(GlobalContext);
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return (
        <div className='print_show'>
            <Container>
                <Row className='justify-content-md-center mt-3'>
                    <Image src={Logo} style={{ width: 110, height: 100 }} />
                    <center><span>لیستی ژمێریاری فێرخواز {studentState.student_chekout.length > 0 ? "(" + studentState.student_chekout[0].student_name + ")" : ""} لە بەرواری {studentState.student_chekout.length > 0 ? moment(studentState.student_chekout[studentState.student_chekout.length - 1].date).format('YYYY-MM-DD') : ""} بۆ بەرواری {today}</span></center>
                </Row>
                <Row className='justify-content-md-center mt-4'>
                    <table>
                        <thead style={{ border: '2px solid', textAlign: 'center' }}>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>ناو</th>
                                <th>ژمارە</th>
                                <th>جۆر</th>
                                <th>بڕ</th>
                                <th>بەروار</th>
                                <th>تێبینی</th>
                            </tr>
                        </thead>
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            {studentState.student_chekout.map((lis) => (
                                lis.type == 'income' ?
                                    <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                        <td>{lis.student_name}</td>
                                        <td>{lis.student_phone1}</td>
                                        <td>داهات - {lis.student_income_type == 'other' ? "هیتر" : lis.student_income_type == 'scissors' ? "قیست" : "سزا"}</td>
                                        <td>{numberbeautifuly(lis.student_income_amount)} IQD</td>
                                        <td>{moment(lis.date).format('YYYY-MM-DD')}</td>
                                        <td>{lis.student_income_note}</td>
                                    </tr>
                                    :
                                    <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                        <td>{lis.student_name}</td>
                                        <td>{lis.student_phone1}</td>
                                        <td>خەرجی - {lis.student_expense_type == "scissors" ? "قەرز" : "هیتر"}</td>
                                        <td>{numberbeautifuly(lis.student_expense_amount)} IQD</td>
                                        <td>{moment(lis.date).format('YYYY-MM-DD')}</td>
                                        <td>{lis.student_expense_note}</td>
                                    </tr>
                            ))}
                            <tr>
                                <td>تێکڕای ماوە</td>
                                <td colSpan={5}>{numberbeautifuly(studentState.total_expense - studentState.total_income)} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <div style={{ width: '100%', marginTop: '5vh', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%' }}>
                        <span>سارا سعدی عمر<br />
                            ژمێریار</span>
                    </div>
                    <div style={{ width: '50%', display: 'flex', justifyContent: "flex-end" }}>
                        <span>ئاڤان ئەحمەد حەمە<br />
                            بەڕێوەبەر</span>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Chekoutprint