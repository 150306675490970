import { useaxios } from "../../../../utils/useaxios";
export default (date) => employDispatch => {
    useaxios('reports/salary_list.php', { date }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            employDispatch({ type: 'setsalarylist', payload: res.employs });
        } else {
            // clear();
        }
    });
}