import { MdSdCardAlert } from "react-icons/md";
import { useaxios } from "../../../../utils/useaxios";
export default (lesson_part) => recaller => setrecaller => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    useaxios('setting/update.php', { lesson_part }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            setrecaller(recaller + 1);
            alert("گۆڕانکاری سەرکەوتوبوو");
        } else {
            // clear();
        }
    });
}