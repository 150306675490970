import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { LoginContext } from '../../../home/logincontext';
import { GlobalContext } from '../../context/provider';
import numberbeautifuly from '../../../utils/numberbeautify';

const Balance_report_detail_modal = props => {
    const { reportState, reportDispatch } = useContext(GlobalContext);

    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Modal.Header dir='rtl'>
                        <Modal.Title>لیستی تێکڕای هەژماری خوێندنگە</Modal.Title>
                    </Modal.Header>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Table striped bordered hover size="sm">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th colSpan={2}>تێکڕای داهات</th>
                                </tr>
                                <tr>
                                    <th>جۆری داهات</th>
                                    <th>بڕی داهات</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                <tr>
                                    <td>سزا</td>
                                    <td>{numberbeautifuly(reportState.income_punish)} IQD</td>
                                </tr>
                                <tr>
                                    <td>قیست</td>
                                    <td>{numberbeautifuly(reportState.income_scissors)} IQD</td>
                                </tr>
                                <tr>
                                    <td>داهاتی تر</td>
                                    <td>{numberbeautifuly(reportState.income_other)} IQD</td>
                                </tr>
                                <tr>
                                    <td>کۆی داهات</td>
                                    <td>{numberbeautifuly(reportState.total_income)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Table striped bordered hover size="sm">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th colSpan={2}>تێکڕای خەرجی</th>
                                </tr>
                                <tr>
                                    <th>جۆری خەرجی</th>
                                    <th>بڕی خەرجی</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                <tr>
                                    <td>موچە</td>
                                    <td>{numberbeautifuly(reportState.expense_salary)} IQD</td>
                                </tr>
                                <tr>
                                    <td>خەرجی ڕۆژانە</td>
                                    <td>{numberbeautifuly(reportState.daily_expense)} IQD</td>
                                </tr>
                                <tr>
                                    <td>خەرجی تر</td>
                                    <td>{numberbeautifuly(reportState.expense_other)} IQD</td>
                                </tr>
                                <tr>
                                    <td>کۆی خەرجی</td>
                                    <td>{numberbeautifuly(reportState.total_expense)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Table striped bordered hover size="sm">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th colSpan={2}>{reportState.balance > 0 ? "کۆی ماوە" : "کۆی کورت هێنان"}</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                <tr>
                                    <td>{numberbeautifuly(reportState.balance)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => {
                        props.setshow(false);
                        props.setreporttype('balance');
                        props.printit();
                    }}>
                        پرینت
                    </Button>
                    <Button variant="primary" onClick={() => {
                        props.setreporttype('');
                        props.setshow(false);
                    }}>
                        داخستن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Balance_report_detail_modal