import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdEditNote } from "react-icons/md";
import Certificateform from './certificateform';
import Certificatelist from './certificatelist';

const Certificate = () => {
    return (
        <div dir="rtl">
            <Container>
                <Row>
                    <Col lg={12}>
                        <Certificateform />
                    </Col>
                    <Col lg={12}>
                        <Certificatelist />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Certificate