const Teacherreducer = (state, { type, payload }) => {
    switch (type) {
        case 'setteacher':
            return { ...state, teacher: payload.teacher, active_teacher: payload.active_teacher };
        case 'setsearch':
            return { ...state, teacher: payload };
        case 'setactivesearch':
            return { ...state, active_teacher: payload };
        case 'setchekout':
            return { ...state, teacher_chekout: payload };
        default:
            return state;
    }
}

export default Teacherreducer;