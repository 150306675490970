import { useaxios } from "../../../../utils/useaxios";
export default (certificate_name, certificate_amount, certificate_id) => recaller => setrecaller => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    useaxios('certificate/update.php', { certificate_name, certificate_amount, certificate_id }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            setrecaller(recaller + 1);
        } else {
            // clear();
        }
    });
}