import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { LoginContext } from '../../../home/logincontext';
import { GlobalContext } from '../../context/provider';
import numberbeautifuly from '../../../utils/numberbeautify';

const Loan_report_detail_modal = props => {
    const { reportState, reportDispatch } = useContext(GlobalContext);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Modal.Header dir='rtl'>
                        <Modal.Title>لیستی قەرز</Modal.Title>
                    </Modal.Header>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>ژمارە</th>
                                    <th>ناو</th>
                                    <th>پۆل</th>
                                    <th>ژمارە</th>
                                    <th>ژمارەی وەسڵ</th>
                                    <th>کۆی قەرز</th>
                                    <th>کۆی ماوە</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportState.student_stat.map((lis, index) => (
                                    // (lis.total_ex - lis.total_inc) != 0 ?
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{lis.student_name}</td>
                                        <td>{lis.class_name}</td>
                                        <td>{lis.student_phone1}</td>
                                        <td>{lis.student_code}</td>
                                        <td>{numberbeautifuly(lis.total_ex)} IQD</td>
                                        <td>{numberbeautifuly(lis.total_ex - lis.total_inc)} IQD</td>
                                    </tr>
                                ))}
                                {/* {reportState.student_stat.map((lis) => (
                                    (lis.total_ex - lis.total_inc) != 0 ?
                                        <tr>
                                            <td>{lis.student_name}</td>
                                            <td>{lis.class_name}</td>
                                            <td>{lis.student_phone1}</td>
                                            <td>{lis.student_code}</td>
                                            <td>{numberbeautifuly(lis.total_ex)} IQD</td>
                                            <td>{numberbeautifuly(lis.total_ex - lis.total_inc)} IQD</td>
                                        </tr> : null
                                ))} */}
                                <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={4}>کۆی قەرزی ماوە</td>
                                    <td style={{ textAlign: 'center' }} colSpan={3}>{numberbeautifuly(reportState.total_loan)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => {
                        props.setshow(false);
                        props.setreporttype('loan');
                        props.printit();
                    }}>
                        پرینت
                    </Button>
                    <Button variant="primary" onClick={() => {
                        props.setreporttype('');
                        props.setshow(false);
                    }}>
                        داخستن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Loan_report_detail_modal