import React, { useContext, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Logo from '../../../margretlogo.png';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import { GlobalContext } from '../../context/provider';
import moment from "moment";
import numberbeautify from '../../../utils/numberbeautify';
import '../../css/onprinttoggle.css';

const Salarylistdetailprint = props => {
    const { employState, employDispatch } = useContext(GlobalContext);
    return (
        <div className='print_show'>
            <Container>
                <Row className='justify-content-md-center mt-3'>
                    <Image src={Logo} style={{ width: 110, height: 100 }} />
                    <center><span>وردەکاری لیستی موچەی مامۆستایان و کارمەندانی کۆمەڵگەی پەروەردەیی مارگرێت مانگی {moment(props.date).format('YYYY-MM')}</span></center>
                </Row>
                <Row className='justify-content-md-center mt-3'>
                    <table>
                        <thead style={{ border: '2px solid', textAlign: 'center', fontSize: 10 }}>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>ناوی بەش</th>
                                <th>کۆی خەرجی بۆ موچە</th>
                                <th>بەروار</th>
                            </tr>
                        </thead>
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 10 }}>
                            {employState.salary_detail.map((lis) => (
                                <tr>
                                    <td>{lis.department_name}</td>
                                    <td>{numberbeautify(lis.total_salary_detail)} IQD</td>
                                    <td>{moment(props.date).format('YYYY-MM')}</td>
                                </tr>
                            ))}
                            <tr style={{ border: '2px solid' }}>
                                <td colSpan={2}>کۆی خەرجی</td>
                                <td>{numberbeautify(employState.salary_detail.reduce((a, v) => a = Number(a) + Number(v.total_salary_detail), 0))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <div style={{ width: '100%', marginTop: '5vh', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%' }}>
                        <span>سارا سعدی عمر<br />
                            ژمێریار</span>
                    </div>
                    <div style={{ width: '50%', display: 'flex', justifyContent: "flex-end" }}>
                        <span>ئاڤان ئەحمەد حەمە<br />
                            بەڕێوەبەر</span>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Salarylistdetailprint