import { useaxios } from "../../../../utils/useaxios";
export default (daily_expense_amount, daily_expense_type, daily_expense_location, daily_expense_date, daily_expense_note, daily_expense_id) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setloading(true);
    useaxios('daily_expense/update.php', { daily_expense_amount, daily_expense_type, daily_expense_location, daily_expense_date, daily_expense_note, daily_expense_id }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}