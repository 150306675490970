import { useaxios } from "../../../../utils/useaxios";
export default (company_expense_purpose, company_expense_amount, company_expense_invoiceno, company_expense_note, company_expense_date) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setloading(true);
    useaxios('company_expense/create.php', { company_expense_purpose, company_expense_amount, company_expense_invoiceno, company_expense_note, company_expense_date }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}