import React, { useContext, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Logo from '../../../margretlogo.png';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import { GlobalContext } from '../../context/provider';
import moment from "moment";
import numberbeautify from '../../../utils/numberbeautify';
import '../../css/onprinttoggle.css';

const Printsalarylist = props => {
    const { employState, employDispatch } = useContext(GlobalContext);
    return (
        <div className='print_show' style={{ fontSize: '12px' }}>
            <Row className='justify-content-md-center mt-3'>
                <Image src={Logo} style={{ width: 110, height: 100 }} />
                <center><span>لیستی موچەی مامۆستایان و کارمەندانی کۆمەڵەی مارگرێت مانگی {moment(props.date).format('YYYY-MM')}</span></center>
            </Row>
            <table className="table table-bordered w-100">
                <thead style={{ border: '2px solid #000' }}>
                    <th style={{ border: '2px solid #000' }}>ناو</th>
                    <th style={{ border: '2px solid #000' }}>بڕی پارە</th>
                    <th style={{ border: '2px solid #000' }}>پیشە</th>
                    <th style={{ border: '2px solid #000' }}>بەشە وانە</th>
                    <th style={{ border: '2px solid #000' }}>ئیسمی</th>
                    <th style={{ border: '2px solid #000' }}>بڕوانامە</th>
                    <th style={{ border: '2px solid #000' }}>خزمەت</th>
                    <th style={{ border: '2px solid #000' }}>کۆمیشنی ڕێککەوتن</th>
                    <th style={{ border: '2px solid #000' }}>شوێن</th>
                    <th style={{ border: '2px solid #000' }}>پاداشت</th>
                    <th style={{ border: '2px solid #000' }}>سزا</th>
                    <th style={{ border: '2px solid #000' }}>تێبینی</th>
                </thead>
                <tbody>
                    {employState.salary_list.map((lis, index) => (
                        <tr style={{ border: '2px solid #000' }}>
                            <td style={{ border: '2px solid #000' }}>{lis.employ_name}</td>
                            <td style={{ border: '2px solid #000' }}>{numberbeautify(lis.main_salary)} IQD</td>
                            <td style={{ border: '2px solid #000' }}>{lis.employ_job}</td>
                            <td style={{ border: '2px solid #000' }}>{lis.lesson_part}</td>
                            <td style={{ border: '2px solid #000' }}>{lis.employ_salary} IQD</td>
                            <td style={{ border: '2px solid #000' }}>{lis.certificate_name}</td>
                            <td style={{ border: '2px solid #000' }}>{numberbeautify(lis.employ_service_amount)} IQD</td>
                            <td style={{ border: '2px solid #000' }}>{numberbeautify(lis.employ_agrement_amount)} IQD</td>
                            <td style={{ border: '2px solid #000' }}>{lis.department_name}</td>
                            <td style={{ border: '2px solid #000' }}>{lis.empenses}</td>
                            <td style={{ border: '2px solid #000' }}>{lis.incomes}</td>
                            <td style={{ border: '2px solid #000' }}>{lis.note}</td>
                        </tr>
                    ))}
                </tbody>

            </table>
            <table className='table table-bordered table-fit table-centered '>
                <tbody>
                    <tr style={{ border: '2px solid #000' }}>
                        <th style={{ textAlign: 'center', border: '2px solid #000' }}>کۆی گشتی</th>
                        <td style={{ textAlign: 'center', border: '2px solid #000' }} >{numberbeautify(employState.salary_list.reduce((a, v) => a = Number(a) + Number(v.main_salary), 0))} IQD</td>
                    </tr>
                </tbody>
            </table>
            <div style={{ width: '100%', marginTop: '5vh', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '50%' }}>
                    <span>سارا سعدی عمر<br />
                        ژمێریار</span>
                </div>
                <div style={{ width: '50%', display: 'flex', justifyContent: "flex-end" }}>
                    <span>ئاڤان ئەحمەد حەمە<br />
                        بەڕێوەبەر</span>
                </div>
            </div>
        </div>
    )
}

export default Printsalarylist