import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import deleteincomeaction from '../../context/action/employ/deleteincomeaction';
import deleteexpenseaction from '../../context/action/employ/deleteexpenseaction';
import numberbeautify from '../../../utils/numberbeautify';
import moment from 'moment';

const Checkoutmodal = props => {
    const { employState } = useContext(GlobalContext);
    const { loading, setloading } = useContext(LoginContext);

    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Modal.Header dir='rtl'>
                        <Modal.Title>لیستی ژمێریاری کارمەند</Modal.Title>
                    </Modal.Header>
                    <Row className='justify-content-md-center mt-5'>
                        <Col lg={11}>
                            <Table striped bordered hover size="lg" style={{ textAlign: 'center' }} dir="rtl">
                                <thead>
                                    <tr>
                                        <th>ناو</th>
                                        <th>ژمارە</th>
                                        <th>جۆر</th>
                                        <th>بڕ</th>
                                        <th>بەروار</th>
                                        <th>تێبینی</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: 12 }}>
                                    {employState.employ_chekout.map((lis) => (
                                        lis.type == "income" ?
                                            <tr style={{ cursor: 'pointer' }} onClick={() => {
                                                if (window.confirm("ئاگاداری ڕەشکردنەوە . دڵنیای ؟") == true) {
                                                    deleteincomeaction(lis.employ_income_id)(setloading);
                                                    props.setshow(false);
                                                }
                                            }}>
                                                <td>{lis.employ_name}</td>
                                                <td>{lis.employ_phone1}</td>
                                                <td>{lis.employ_income_type == 'punish' ? "سزا" : " داهات - هی دیکە"}</td>
                                                <td>{numberbeautify(lis.employ_income_amount)} IQD</td>
                                                <td>{moment(lis.date).format('YYYY-MM')} </td>
                                                <td>{lis.employ_income_note}</td>
                                            </tr>
                                            :
                                            <tr style={{ cursor: 'pointer' }} onClick={() => {
                                                if (window.confirm("ئاگاداری ڕەشکردنەوە . دڵنیای ؟") == true) {
                                                    deleteexpenseaction(lis.employ_expense_id)(setloading);
                                                    props.setshow(false);
                                                }
                                            }}>
                                                <td>{lis.employ_name}</td>
                                                <td>{lis.employ_phone1}</td>
                                                <td>{lis.employ_expense_type == 'salary' ? "موچە" : lis.employ_expense_type == 'lesson_part' ? "بەشەوانە" : lis.employ_expense_type == 'certificate' ? "بڕوانامە" : lis.employ_expense_type == 'service' ? "خزمەت" : lis.employ_expense_type == "reward" ? "پاداشت" : lis.employ_expense_type == "argment" ? "کۆمیشنی ڕێککەوتن" : "خەرجی - هی دیکە"}</td>
                                                <td>{numberbeautify(lis.employ_expense_amount)} IQD</td>
                                                <td>{lis.date}</td>
                                                <td>{lis.employ_expense_note}</td>
                                            </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" disabled={employState.employ_chekout.length > 0 ? false : true} onClick={() => {
                        props.setshow(false);
                        props.printit();
                    }}>
                        پرینت
                    </Button>
                    <Button variant="primary" onClick={() => {
                        props.setshow(false);
                    }}>
                        داخستن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Checkoutmodal