import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Addstudentmodal from './addstudentmodal';
import { GlobalContext } from '../../context/provider';
import Editstudentmodal from './editstudentmodal';
import changestateaction from '../../context/action/student/changestateaction';
import { LoginContext } from '../../../home/logincontext';
import searchaction from '../../context/action/student/searchaction';
import Studentdetailmodal from './studentdetailmodal';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdEditNote } from "react-icons/md";
import numberbeautifuly from '../../../utils/numberbeautify';

const Student = () => {
    const { studentState, studentDispatch } = useContext(GlobalContext);
    const { loading, setloading } = useContext(LoginContext);

    const [search, setsearch] = useState();

    const [show, setshow] = useState(false);
    const [show1, setshow1] = useState(false);
    const [show2, setshow2] = useState(false);

    const [student_id, setstudent_id] = useState();
    const [student_name, setstudent_name] = useState();
    const [student_address, setstudent_address] = useState();
    const [student_phone1, setstudent_phone1] = useState();
    const [student_phone2, setstudent_phone2] = useState();
    const [student_phone3, setstudent_phone3] = useState();
    const [student_gender, setstudent_gender] = useState();
    const [student_birthday, setstudent_birthday] = useState();
    const [student_blood, setstudent_blood] = useState();
    const [student_note, setstudent_note] = useState();
    const [student_cost, setstudent_cost] = useState();
    const [student_code, setstudent_code] = useState();
    const [student_passport, setstudent_passport] = useState();
    const [student_class, setstudent_class] = useState();
    const [student_detail, setstudent_detail] = useState({});
    return (
        <div dir='rtl'>
            <Container>
                <Addstudentmodal show={show} setshow={setshow} />
                <Studentdetailmodal show={show2} setshow={setshow2} lis={student_detail} />
                <Editstudentmodal student_id={student_id} setstudent_id={setstudent_id} student_name={student_name} setstudent_name={setstudent_name} student_address={student_address} setstudent_address={setstudent_address} student_phone1={student_phone1} setstudent_phone1={setstudent_phone1} student_phone2={student_phone2} setstudent_phone2={setstudent_phone2} student_phone3={student_phone3} setstudent_phone3={setstudent_phone3} student_gender={student_gender} setstudent_gender={setstudent_gender} student_birthday={student_birthday} setstudent_birthday={setstudent_birthday} student_blood={student_blood} setstudent_blood={setstudent_blood} student_note={student_note} setstudent_note={setstudent_note} student_cost={student_cost} setstudent_cost={setstudent_cost} student_class={student_class} setstudent_class={setstudent_class} student_code={student_code} setstudent_code={setstudent_code} student_passport={student_passport} setstudent_passport={setstudent_passport} show={show1} setshow={setshow1} />
                <Row className='mt-4'>
                    <center><h3><b> پەیمانگە - فێرخواز </b></h3></center>
                </Row>
                <Row className='mt-3'>
                    <Col lg={1} />
                    <Col lg={5} className="mt-4">
                        <Button onClick={() => {
                            setshow(true);
                        }}>زیادکردن</Button>
                    </Col>
                    <Col lg={5}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>گەڕان</Form.Label>
                            <Form.Control type="text" placeholder="گەڕان" value={search} onChange={(val) => searchaction(val.target.value)(studentDispatch)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='justify-content-md-center mt-5'>
                    <Col lg={11}>
                        <Table striped bordered hover size="lg" style={{ textAlign: 'center' }} >
                            <thead>
                                <tr>
                                    <th>کۆد</th>
                                    <th>ناو</th>
                                    <th>ساڵی خوێندن</th>
                                    <th>ژمارەی وەسڵ</th>
                                    <th>تێچوی خوێندن</th>
                                    <th>دۆخ</th>
                                    <th>ڕێکخستن</th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: 12 }}>
                                {studentState.student.map((lis) => (
                                    <tr>
                                        <td>{lis.student_id}</td>
                                        <td onClick={() => { setstudent_detail(lis); console.log(lis); setshow2(true); }} style={{ cursor: "pointer" }}>{lis.student_name}</td>
                                        <td>{lis.student_birthday}</td>
                                        <td>{lis.student_code}</td>
                                        <td>{numberbeautifuly(lis.student_cost)} IQD</td>
                                        <td>{lis.student_state}</td>
                                        <td style={{ fontSize: 22 }}>
                                            <a style={{ color: lis.student_state == 'active' ? "#dc3545" : "green", marginLeft: 15 }} href="#" onClick={() => {
                                                changestateaction(lis.student_id, lis.student_state)(setloading);
                                            }}>
                                                <AiOutlineCheckCircle />
                                            </a>
                                            <a style={{ color: '#f39c12', fontSize: 26 }} href="#" onClick={() => {
                                                setstudent_id(lis.student_id);
                                                setstudent_name(lis.student_name);
                                                setstudent_address(lis.student_address);
                                                setstudent_gender(lis.student_gender);
                                                setstudent_birthday(lis.student_birthday);
                                                setstudent_phone1(lis.student_phone1);
                                                setstudent_phone2(lis.student_phone2);
                                                setstudent_phone3(lis.student_phone3);
                                                setstudent_blood(lis.student_blood);
                                                setstudent_note(lis.student_note);
                                                setstudent_cost(lis.student_cost);
                                                setstudent_code(lis.student_code);
                                                setstudent_passport(lis.student_passport);
                                                setstudent_class(lis.class_id);
                                                setshow1(true);
                                            }}>
                                                <MdEditNote />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Student