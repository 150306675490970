import React, { useContext, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Logo from '../../../margretlogo.png';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import { GlobalContext } from '../../context/provider';
import moment from "moment";
import '../css/onprinttoggle.css';
import numberbeautifuly from '../../../utils/numberbeautify';

const Income_report_detail_print = props => {
    const { reportState, reportDispatch } = useContext(GlobalContext);
    return (
        <div className='print_show'>
            <Container>
                <Row className='justify-content-md-center mt-3'>
                    <Image src={Logo} style={{ width: 110, height: 100 }} />
                    <center><span>لیستی داهاتی کۆمپانیا لە بەرواری {moment(props.from).format('YYYY-MM-DD')} بۆ بەرواری {moment(props.to).format('YYYY-MM-DD')}</span></center>
                </Row>
                <Row className='justify-content-md-center mt-4'>
                    <table>
                        <thead style={{ border: '2px solid', textAlign: 'center' }}>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>ناوی کەس</th>
                                <th>بڕ</th>
                                <th>ژمارەی پسوڵە</th>
                                <th>تێبینی</th>
                                <th>بەروار</th>
                            </tr>
                        </thead>
                        <tbody style={{ border: '2px solid', textAlign: 'center', fontSize: 12 }}>
                            {reportState.income.map((lis) => (
                                <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                    <td>{lis.company_income_purpose}</td>
                                    <td>{numberbeautifuly(lis.company_income_amount)} IQD</td>
                                    <td>{lis.company_income_invoiceno}</td>
                                    <td>{lis.company_income_note}</td>
                                    <td>{moment(lis.company_income_date).format('YYYY-MM-DD')}</td>
                                </tr>
                            ))}
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <td style={{ border: '2px solid', textAlign: 'center' }}>کۆی داهات</td>
                                <td colSpan={4}>{numberbeautifuly(reportState.income.reduce((a, v) => a = Number(a) + Number(v.company_income_amount), 0))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <div style={{ width: '100%', marginTop: '5vh', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%' }}>
                        <span>سارا سعدی عمر<br />
                            ژمێریار</span>
                    </div>
                    <div style={{ width: '50%', display: 'flex', justifyContent: "flex-end" }}>
                        <span>ئاڤان ئەحمەد حەمە<br />
                            بەڕێوەبەر</span>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Income_report_detail_print