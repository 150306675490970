import React, { createContext, useReducer, useEffect, useState } from "react";
import initstate from "./initialState/initstate";
import Initreducer from "./reducer/initreducer";
import init from "./action/init/init";
import Studentreducer from "./reducer/studentreducer";
import studentstate from "./initialState/studentstate";
import Teacherreducer from "./reducer/teacherreducer";
import teacherstate from "./initialState/teacherstate";
import Employreducer from "./reducer/employreducer";
import employstate from "./initialState/employstate";
import Reportreducer from "./reducer/report";
import report from "./initialState/report";
import Dailyexpensereducer from "./reducer/dailyexpensereducer";
import dailyexpensestate from "./initialState/dailyexpensestate";
export const GlobalContext = createContext({});

const GlobalProvider = ({ children }) => {
    const [initState, initDispatch] = useReducer(Initreducer, initstate);
    const [studentState, studentDispatch] = useReducer(Studentreducer, studentstate);
    const [teacherState, teacherDispatch] = useReducer(Teacherreducer, teacherstate);
    const [employState, employDispatch] = useReducer(Employreducer, employstate);
    const [reportState, reportDispatch] = useReducer(Reportreducer, report);
    const [dailyexpenseState, dailyexpenseDispatch] = useReducer(Dailyexpensereducer, dailyexpensestate);

    const [recaller, setrecaller] = useState(0);


    useEffect(() => {
        init()(initDispatch)(studentDispatch)(teacherDispatch)(employDispatch)(dailyexpenseDispatch);
    }, [recaller]);
    return <GlobalContext.Provider value={{
        initState, initDispatch,
        studentState, studentDispatch,
        teacherState, teacherDispatch,
        employState, employDispatch,
        reportState, reportDispatch,
        dailyexpenseState, dailyexpenseDispatch,
        recaller, setrecaller,
    }}>{children}</GlobalContext.Provider>
}

export default GlobalProvider;