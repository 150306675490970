import { useaxios } from "../../../../utils/useaxios";
export default (boss_expense_nameandpurpose, boss_expense_amount, boss_expense_invoiceno, boss_expense_location, boss_expense_note, boss_expense_id, boss_expense_date) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setloading(true);
    useaxios('boss_expense/update.php', { boss_expense_nameandpurpose, boss_expense_amount, boss_expense_invoiceno, boss_expense_location, boss_expense_note, boss_expense_id, boss_expense_date }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}