import { useaxios } from "../../../../utils/useaxios";
export default (employ_id, amount, employ_income_date, note, department_id) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    setloading(true);
    useaxios('employ_income/create.php', { employ_income_type: 'punish', employ_income_amount: amount, employ_income_note: note, employ_income_date, employ_id, department_id }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}