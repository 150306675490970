import { useaxios } from "../../../../utils/useaxios";
export default (radio_expense_name, radio_expense_amount, radio_expense_type, radio_expense_date, radio_expense_note) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setloading(true);
    useaxios('radio_expense/create.php', { radio_expense_name, radio_expense_amount, radio_expense_type, radio_expense_date, radio_expense_note }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}