import { useaxios } from "../../../../utils/useaxios";
export default (text) => studentDispatch => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // setloading(true);
    console.log(text);
    useaxios('student/search.php', { text, department_id: 1 }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            studentDispatch({ type: 'setsearch', payload: res.student });
        } else {
            // clear();
        }
        // setloading(false);
    });
}