import { useaxios } from "../../../../utils/useaxios";
export default (teacher_id, amount, boss_expense_nameandpurpose, boss_expense_invoiceno, boss_expense_location, boss_expense_date, note) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    setloading(true);
    useaxios('boss_expense/create.php', { boss_expense_type: 'other', boss_expense_amount: amount, boss_expense_nameandpurpose, boss_expense_invoiceno, boss_expense_location, boss_expense_note: note, boss_expense_date }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}