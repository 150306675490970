import { useaxios } from "../../../../utils/useaxios";
export default (project_expense_id) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setloading(true);
    useaxios('project_expense/delete.php', { project_expense_id }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}