import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdEditNote } from "react-icons/md";
import Addemploymodal from './addemploymodal';
import Editemploymodal from './editemploymodal';
import changestateaction from '../../context/action/employ/changestateaction';
import searchaction from '../../context/action/employ/searchaction';
import Employdetailmodal from './employdetailmodal';
import numberbeautify from '../../../utils/numberbeautify';

const Employ = () => {
    const { employState, employDispatch } = useContext(GlobalContext);
    const { setloading } = useContext(LoginContext);

    const [show, setshow] = useState(false);
    const [show1, setshow1] = useState(false);
    const [show2, setshow2] = useState(false);
    const [search, setsearch] = useState();

    const [employ_id, setemploy_id] = useState();
    const [employ_name, setemploy_name] = useState();
    const [employ_phone1, setemploy_phone1] = useState();
    const [employ_job, setemploy_job] = useState();
    const [lesson_part, setlesson_part] = useState();
    const [employ_email, setemploy_email] = useState();
    const [employ_salary, setemploy_salary] = useState();
    const [employ_agrement_amount, setemploy_agrement_amount] = useState();
    const [employ_service_amount, setemploy_service_amount] = useState();
    const [employ_note, setemploy_note] = useState();
    const [employ_certificate, setemploy_certificate] = useState();
    const [department_id, setdepartment_id] = useState();
    const [employ_detail, setemploy_detail] = useState({});
    return (
        <div dir="rtl">
            <Container>
                <Addemploymodal show={show} setshow={setshow} />
                <Editemploymodal show={show1} setshow={setshow1} employ_id={employ_id} setemploy_id={setemploy_id} employ_name={employ_name} setemploy_name={setemploy_name} employ_phone1={employ_phone1} setemploy_phone1={setemploy_phone1} employ_job={employ_job} setemploy_job={setemploy_job} lesson_part={lesson_part} setlesson_part={setlesson_part} employ_email={employ_email} setemploy_email={setemploy_email} employ_salary={employ_salary} setemploy_salary={setemploy_salary} employ_agrement_amount={employ_agrement_amount} setemploy_agrement_amount={setemploy_agrement_amount} employ_service_amount={employ_service_amount} setemploy_service_amount={setemploy_service_amount} employ_note={employ_note} setemploy_note={setemploy_note} employ_certificate={employ_certificate} setemploy_certificate={setemploy_certificate} department_id={department_id} setdepartment_id={setdepartment_id} />
                <Employdetailmodal show={show2} setshow={setshow2} lis={employ_detail} />
                <Row className='mt-4'>
                    <center><h3><b> کارمەند </b></h3></center>
                </Row>
                <Row className='mt-3'>
                    <Col lg={1} />
                    <Col lg={5} className="mt-4">
                        <Button onClick={() => {
                            setshow(true);
                        }}>زیادکردن</Button>
                    </Col>
                    <Col lg={5}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>گەڕان</Form.Label>
                            <Form.Control type="text" placeholder="گەڕان" value={search} onChange={(val) => { searchaction(val.target.value)(employDispatch) }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='justify-content-md-center mt-5'>
                    <Col lg={11}>
                        <Table striped bordered hover size="lg" style={{ textAlign: 'center' }}>
                            <thead>
                                <tr>
                                    <th>ناو</th>
                                    <th>ژمارە</th>
                                    <th>پیشە</th>
                                    <th>بڕوانامە</th>
                                    <th>موچە</th>
                                    <th>دۆخ</th>
                                    <th>ڕێکخستن</th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: 12 }}>
                                {employState.employ.map((lis) => (
                                    <tr>
                                        <td style={{ cursor: 'pointer' }} onClick={() => { setemploy_detail(lis); setshow2(true); }}>{lis.employ_name}</td>
                                        <td>{lis.employ_phone1}</td>
                                        <td>{lis.employ_job}</td>
                                        <td>{lis.certificate_name}</td>
                                        <td>{numberbeautify(lis.employ_salary)} IQD</td>
                                        <td>{lis.employ_state}</td>
                                        <td style={{ fontSize: 22 }}>
                                            <a style={{ color: lis.employ_state == 'active' ? "#dc3545" : "green", marginLeft: 15 }} href="#" onClick={() => {
                                                // changestateaction(lis.employ_id, lis.employ_state)(setloading);
                                                changestateaction(lis.employ_id, lis.employ_state)(setloading);
                                            }}>
                                                <AiOutlineCheckCircle />
                                            </a>
                                            <a style={{ color: '#f39c12', fontSize: 26 }} href="#" onClick={() => {
                                                setemploy_id(lis.employ_id);
                                                setemploy_name(lis.employ_name);
                                                setemploy_phone1(lis.employ_phone1);
                                                setemploy_job(lis.employ_job);
                                                setlesson_part(lis.lesson_part);
                                                setemploy_email(lis.employ_email);
                                                setemploy_salary(lis.employ_salary);
                                                setemploy_agrement_amount(lis.employ_agrement_amount)
                                                setemploy_service_amount(lis.employ_service_amount);
                                                setemploy_certificate(lis.employ_certificate);
                                                setdepartment_id(lis.department_id);
                                                setemploy_note(lis.employ_note);
                                                setshow1(true);
                                            }}>
                                                <MdEditNote />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Employ