import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import updateaction from '../../context/action/employ/updateaction';
import deleteaction from '../../context/action/employ/deleteaction';

const Editemploymodal = props => {
    const { employState, recaller, setrecaller } = useContext(GlobalContext);
    const { setloading } = useContext(LoginContext);

    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);
    const [error2, seterror2] = useState(false);
    const [error3, seterror3] = useState(false);
    const [error4, seterror4] = useState(false);
    const [error5, seterror5] = useState(false);
    const [error6, seterror6] = useState(false);
    const [error7, seterror7] = useState(false);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    {/* {JSON.stringify(initState.class[0].class_id)} */}
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error ? "red" : null }}>ناو</Form.Label>
                                <Form.Control style={{ borderColor: error ? "red" : null }} type="text" placeholder="ناو" value={props.employ_name} onChange={(val) => props.setemploy_name(val.target.value)} onFocus={() => seterror(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>ئیمەیڵ</Form.Label>
                                <Form.Control type="text" placeholder="ئیمەیڵ" value={props.employ_email} onChange={(val) => props.setemploy_email(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error5 ? "red" : null }}>بڕوانامە</Form.Label>
                                <Form.Select onChange={(val) => { props.setemploy_certificate(val.target.value); seterror5(false); }} value={props.employ_certificate} dir="ltr" style={{ borderColor: error5 ? "red" : null }}>
                                    <option value={0}>بڕوانامە</option>
                                    {employState.certificate.map((lis) => (
                                        <option value={lis.certificate_id}>{lis.certificate_name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error6 ? "red" : null }}>شوێن</Form.Label>
                                <Form.Select onChange={(val) => { props.setdepartment_id(val.target.value); seterror6(false); }} value={props.department_id} dir="ltr" style={{ borderColor: error6 ? "red" : null }}>
                                    <option value={0}>شوێن</option>
                                    {employState.department.map((lis) => (
                                        <option value={lis.department_id}>{lis.department_name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error1 ? "red" : null }}>ژمارە تەلەفۆن </Form.Label>
                                <Form.Control style={{ borderColor: error1 ? "red" : null }} type="number" placeholder="ژمارە تەلەفۆن " value={props.employ_phone1} onChange={(val) => props.setemploy_phone1(val.target.value)} onFocus={() => seterror1(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>پیشە</Form.Label>
                                <Form.Control type="text" placeholder="پیشە" value={props.employ_job} onChange={(val) => props.setemploy_job(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error3 ? "red" : null }}>موچە</Form.Label>
                                <Form.Control style={{ borderColor: error3 ? "red" : null }} type="number" placeholder="موچە" value={props.employ_salary} onChange={(val) => props.setemploy_salary(val.target.value)} onFocus={() => seterror3(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error4 ? "red" : null }}>بەشەوانە</Form.Label>
                                <Form.Control style={{ borderColor: error4 ? "red" : null }} type="number" placeholder="بەشەوانە" value={props.lesson_part} onChange={(val) => props.setlesson_part(val.target.value)} onFocus={() => seterror4(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error2 ? "red" : null }}>خزمەت</Form.Label>
                                <Form.Control style={{ borderColor: error2 ? "red" : null }} type="number" placeholder="خزمەت" value={props.employ_service_amount} onChange={(val) => props.setemploy_service_amount(val.target.value)} onFocus={() => seterror2(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error7 ? "red" : null }}>کۆمیشنی ڕێککەوتن</Form.Label>
                                <Form.Control style={{ borderColor: error7 ? "red" : null }} type="number" placeholder="کۆمیشنی ڕێککەوتن" value={props.employ_agrement_amount} onChange={(val) => props.setemploy_agrement_amount(val.target.value)} onFocus={() => seterror7(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>تێبینی</Form.Label>
                                <Form.Control type="text" placeholder="تێبینی" value={props.employ_note} onChange={(val) => props.setemploy_note(val.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        if (window.confirm("ئاگاداری ڕەشکردنەوە . دڵنیای ؟") == true) {
                            deleteaction(props.employ_id)(setloading);
                            props.setshow(false);
                        };
                    }}>
                        ڕەشکردنەوە
                    </Button>
                    <Button variant="primary" onClick={() => {
                        if (props.employ_name == '' || !props.employ_name) {
                            seterror(true);
                            return false;
                        }
                        if (props.employ_certificate == 0) {
                            seterror5(true);
                            return false;
                        }
                        if (props.department_id == 0) {
                            seterror6(true);
                            return false;
                        }
                        if (props.employ_salary == '' || props.employ_salary < 1 || !props.employ_salary) {
                            seterror3(true);
                            return false;
                        }
                        if (props.lesson_part == '' || props.lesson_part < 0 || !props.lesson_part) {
                            seterror4(true);
                            return false;
                        }
                        if (props.employ_service_amount == '' || props.employ_service_amount < 0 || !props.employ_service_amount) {
                            seterror2(true);
                            return false;
                        }
                        if (props.employ_agrement_amount == '' || props.employ_agrement_amount < 0 || !props.employ_agrement_amount) {
                            seterror7(true);
                            return false;
                        }
                        // alert(props.employ_id);
                        updateaction(props.employ_name, props.employ_phone1, props.employ_job, props.lesson_part, props.employ_email, props.employ_salary, props.employ_agrement_amount, props.employ_service_amount, props.employ_certificate, props.employ_note, props.department_id, props.employ_id)(recaller)(setrecaller);
                        props.setshow(false);
                    }}>
                        گۆڕانکاری
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Editemploymodal