import React, { useContext } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Logo from '../margretlogo.png';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import InstituteHome from '../institute/screen/instituteHome';
import SchoolHome from '../school/screen/schoolHome';
import KindergartenHome from '../kindergarten/screen/kindergartenHome';
import NurseryHome from '../nursery/screen/nurseryHome';
import Reporthome from '../mainreport/screen/reporthome';
import ManagerHome from '../manager/screen/managerHome';
import Settinghome from '../setting/screen/settinghome';
import Employhome from '../employ/screen/employhome';
import Radiohome from '../radio/screen/radiohome';
import Projecthome from '../project/screen/projecthome';
import CompanyHome from '../company/screen/companyHome';


const Home = () => {
    return (
        <Router>
            <Switch>
                <Route path="/" exact>
                    <div dir='rtl'>
                        <Container>
                            <Row className='justify-content-md-center mt-5'>
                                <Image src={Logo} style={{ width: 150, height: 130 }} />
                            </Row>
                            <Row className='justify-content-md-center mt-5'>
                                <Col lg={3}>
                                    <Link to='/institute/' style={{ textDecoration: 'none', color: '#000' }}>
                                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }}>
                                            <Card.Body>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        <div>پەیمانگە</div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3}>
                                    <Link to='/school/' style={{ textDecoration: 'none', color: '#000' }}>
                                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }}>
                                            <Card.Body>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        <div>خوێندنگە</div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3}>
                                    <Link to='/kindergarten/' style={{ textDecoration: 'none', color: '#000' }}>
                                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }}>
                                            <Card.Body>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        <div>باخچەی منداڵان</div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3}>
                                    <Link to='/nursery/' style={{ textDecoration: 'none', color: '#000' }}>
                                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }}>
                                            <Card.Body>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        <div>دایەنگە</div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            </Row>
                            <Row className='justify-content-md-center mt-5'>
                                <Col lg={3}>
                                    <Link to='/manager/' style={{ textDecoration: 'none', color: '#000' }}>
                                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }}>
                                            <Card.Body>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        <div>خەرجی و هاوکاریەکانی کەریمی عەلەکە</div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3}>
                                    <Link to='/radio/' style={{ textDecoration: 'none', color: '#000' }}>
                                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }}>
                                            <Card.Body>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        <div>رادیۆ </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3}>
                                    <Link to='/project/' style={{ textDecoration: 'none', color: '#000' }}>
                                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }}>
                                            <Card.Body>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        <div>پرۆژە </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={3}>
                                    <Link to='/company/' style={{ textDecoration: 'none', color: '#000' }}>
                                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }}>
                                            <Card.Body>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        <div>کۆمپانیا</div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            </Row>
                            <Row className='justify-content-md-center mt-5'>
                                <Col lg={4}>
                                    <Link to='/employ/' style={{ textDecoration: 'none', color: '#000' }}>
                                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }}>
                                            <Card.Body>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        <div>کارمەند</div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={4}>
                                    <Link to='/setting/' style={{ textDecoration: 'none', color: '#000' }}>
                                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }}>
                                            <Card.Body>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        <div>ڕێکخستنەکانی سیستەم</div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col lg={4}>
                                    <Link to='/mainreport/' style={{ textDecoration: 'none', color: '#000' }}>
                                        <Card style={{ cursor: "pointer", 'box-shadow': " 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)" }}>
                                            <Card.Body>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        <div>ژمێریاری گشتی</div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>

                            </Row>
                        </Container>
                    </div>
                </Route>
                <Route path="/institute">
                    <InstituteHome />
                </Route>
                <Route path="/school">
                    <SchoolHome />
                </Route>
                <Route path="/kindergarten">
                    <KindergartenHome />
                </Route>
                <Route path="/nursery">
                    <NurseryHome />
                </Route>
                <Route path="/mainreport">
                    <Reporthome />
                </Route>
                <Route path="/manager">
                    <ManagerHome />
                </Route>
                <Route path="/setting">
                    <Settinghome />
                </Route>
                <Route path="/company">
                    <CompanyHome />
                </Route>
                <Route path="/employ">
                    <Employhome />
                </Route>
                <Route path="/radio">
                    <Radiohome />
                </Route>
                <Route path="/project">
                    <Projecthome />
                </Route>
            </Switch>
        </Router>
    )
}

export default Home