import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdEditNote } from "react-icons/md";
import Salarymodal from './salarymodal';
import Punishmodal from './punishmodal';
import Othermodal from './othermodal';
import Rewardmodal from './rewardmodal';
import activesearchaction from '../../context/action/employ/activesearchaction';
import checkoutacion from '../../context/action/employ/checkoutacion';
import Checkoutmodal from './checkoutmodal';
import Checkoutprint from './checkoutprint';
import numberbeautify from '../../../utils/numberbeautify';

const Employaccount = () => {
    const { employState, employDispatch } = useContext(GlobalContext);
    const { setloading } = useContext(LoginContext);

    const [salary, setsalary] = useState(0);
    const [employ_id, setemploy_id] = useState(0);
    const [department_id, setdepartment_id] = useState(0);
    const [search, setsearch] = useState();
    const [show, setshow] = useState(false);
    const [show1, setshow1] = useState(false);
    const [show2, setshow2] = useState(false);
    const [show3, setshow3] = useState(false);
    const [show4, setshow4] = useState(false);
    const [employ_detail, setemploy_detail] = useState({});

    const printit = async () => {
        setshow3(false);
        setTimeout(() => { window.print(); }, 250);
    }
    return (
        <div dir='rtl'>
            <Checkoutprint />
            <Container className='print_hide'>
                <Salarymodal show={show} setshow={setshow} salary={salary} setsalary={setsalary} employ_id={employ_id} setemploy_id={setemploy_id} department_id={department_id} setdepartment_id={setdepartment_id} />
                <Punishmodal show={show1} setshow={setshow1} department_id={department_id} employ_id={employ_id} />
                <Othermodal show={show2} setshow={setshow2} department_id={department_id} employ_id={employ_id} />
                <Rewardmodal show={show4} setshow={setshow4} department_id={department_id} employ_id={employ_id} />
                <Checkoutmodal show={show3} setshow={setshow3} printit={printit} />

                <Row className='mt-4'>
                    <center><h3><b> هەژماری کارمەند </b></h3></center>
                </Row>
                <Row className='mt-3'>
                    <Col lg={1} />
                    <Col lg={5}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>گەڕان</Form.Label>
                            <Form.Control type="text" placeholder="گەڕان" value={search} onChange={(val) => { activesearchaction(val.target.value)(employDispatch) }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='justify-content-md-center mt-5'>
                    <Col lg={11}>
                        <Table striped bordered hover size="lg" style={{ textAlign: 'center' }}>
                            <thead>
                                <tr>
                                    <th>ناو</th>
                                    <th>ژمارە</th>
                                    <th>پیشە</th>
                                    <th>بڕوانامە</th>
                                    <th>موچە</th>
                                    <th>دۆخ</th>
                                    <th>ڕێکخستن</th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: 12 }}>
                                {employState.active_employ.map((lis) => (
                                    <tr>
                                        <td style={{ cursor: 'pointer' }} onClick={() => { checkoutacion(lis.employ_id)(setloading)(employDispatch)(setshow3) }}>{lis.employ_name}</td>
                                        <td>{lis.employ_phone1}</td>
                                        <td>{lis.employ_job}</td>
                                        <td>{lis.certificate_name}</td>
                                        <td>{numberbeautify(lis.employ_salary)} IQD</td>
                                        <td>{lis.employ_state}</td>
                                        <td style={{ fontSize: 14 }}>
                                            {/* <a style={{ marginRight: 4 }} href='#'
                                                onClick={() => {
                                                    setsalary(lis.employ_salary);
                                                    setemploy_id(lis.employ_id);
                                                    setdepartment_id(lis.department_id);
                                                    setshow(true);
                                                }}
                                            >موچە</a> */}
                                            <a style={{ marginRight: 4 }} href='#'
                                                onClick={() => {
                                                    setemploy_id(lis.employ_id);
                                                    setdepartment_id(lis.department_id);
                                                    setshow1(true);
                                                }}
                                            >سزا</a>
                                            <a style={{ marginRight: 4 }} href='#'
                                                onClick={() => {
                                                    setemploy_id(lis.employ_id);
                                                    setdepartment_id(lis.department_id);
                                                    setshow4(true);
                                                }}
                                            >پاداشت</a>
                                            <a style={{ marginRight: 4 }} href='#'
                                                onClick={() => {
                                                    setemploy_id(lis.employ_id);
                                                    setdepartment_id(lis.department_id);
                                                    setshow2(true);
                                                }}
                                            >هی دیکە</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Employaccount