import { useaxios } from "../../../../utils/useaxios";
export default () => employDispatch => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    useaxios('employ/init.php').then((res) => {
        console.log(res);
        if (res.status == "ok") {
            employDispatch({ type: 'setemploy', payload: { employ: res.employ, active_employ: res.active_employ, certificate: res.certificate, department: res.department } });
        } else {
            // clear();
        }
    });
}