import { useaxios } from "../../../../utils/useaxios";
export default (student_id) => setloading => studentDispatch => setshow4 => {
    // setloading(true);
    useaxios('student/chekout.php', { student_id }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            setshow4(true);
            studentDispatch({ type: 'setchekout', payload: { checkout: res.student_chekout, total_income: res.student_total_income, total_expense: res.student_total_expense } });
        } else {
            // clear();
        }
        // setloading(false);
    });
}