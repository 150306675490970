import { useaxios } from "../../../../utils/useaxios";
export default () => initDispatch => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    useaxios('company_income/init.php', { today }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            initDispatch({ type: 'setinit', payload: { income: res.incomes, expense: res.expenses } });
        } else {
            // clear();
        }
    });
}