import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import deleteaction from '../../context/action/income/deleteaction';
import updateaction from '../../context/action/income/updateaction';

const Editincomemodal = props => {
    const { initState } = useContext(GlobalContext);
    const { loading, setloading } = useContext(LoginContext);

    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);
    const [error2, seterror2] = useState(false);
    const [error3, seterror3] = useState(false);
    const [error4, seterror4] = useState(false);
    const [error5, seterror5] = useState(false);
    const [error6, seterror6] = useState(false);
    const [error7, seterror7] = useState(false);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    {/* {JSON.stringify(initState.class[0].class_id)} */}
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label >ناوی کەس </Form.Label>
                                <Form.Control type="text" placeholder="ناوی کەس " value={props.name} onChange={(val) => props.setname(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error ? "red" : null }}>بڕ</Form.Label>
                                <Form.Control style={{ borderColor: error ? "red" : null }} type="number" placeholder="بڕ" value={props.amount} onChange={(val) => props.setamount(val.target.value)} onFocus={() => seterror(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label >ژمارەی پسوڵە</Form.Label>
                                <Form.Control type="text" placeholder="ژمارەی پسوڵە" value={props.invoice} onChange={(val) => props.setinvoice(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10} className="mt-3">
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error1 ? "red" : null }}>بەرواری پسوڵە</Form.Label>
                                <Form.Control style={{ borderColor: error1 ? "red" : null }} type="date" placeholder="بەرواری پسوڵە" value={props.date} onChange={(val) => props.setdate(val.target.value)} onFocus={() => seterror1(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>تێبینی</Form.Label>
                                <Form.Control type="text" placeholder="تێبینی" value={props.note} onChange={(val) => props.setnote(val.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        if (window.confirm("ئاگاداری ڕەشکردنەوە . دڵنیای ؟") == true) {
                            deleteaction(props.income_id)(setloading);
                            props.setshow(false);
                        }
                    }}>
                        ڕەشکردنەوە
                    </Button>
                    <Button variant="primary" onClick={() => {
                        updateaction(props.name, props.amount, props.invoice, props.note, props.date, props.income_id)(setloading);
                        // if (window.confirm("ئاگاداری گۆڕانکاری . دڵنیای ؟") == true) {
                        // updateaction(props.name, props.amount, props.invoice, props.note, props.date, props.income_id)(setloading);
                        props.setshow(false);
                        // }
                    }}>
                        گۆڕانکاری
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Editincomemodal