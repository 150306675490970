import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import createaction from '../../context/action/expense/createaction';

const Addexpensemodal = props => {
    const { setloading } = useContext(LoginContext);

    const [amount, setamount] = useState();
    const [location, setlocation] = useState();
    const [exdate, setexdate] = useState();
    const [extype, setextype] = useState();
    const [note, setnote] = useState();

    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Modal.Header dir='rtl'>
                        <Modal.Title>زیادکردنی خەرجی</Modal.Title>
                    </Modal.Header>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error ? "red" : null }}>بڕ</Form.Label>
                                <Form.Control style={{ borderColor: error ? "red" : null }} type="number" placeholder="بڕ" value={amount} onChange={(val) => setamount(val.target.value)} onFocus={() => seterror(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>شوێن</Form.Label>
                                <Form.Control type="text" placeholder="شوێن" value={location} onChange={(val) => setlocation(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error1 ? "red" : null }}>بەروار</Form.Label>
                                <Form.Control style={{ borderColor: error1 ? "red" : null }} type="date" value={exdate} placeholder="بەروار" onChange={(val) => setexdate(val.target.value)} onFocus={() => seterror1(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label >جۆری خەرجی</Form.Label>
                                <Form.Control type="text" placeholder="جۆری خەرجی" value={extype} onChange={(val) => setextype(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>تێبینی</Form.Label>
                                <Form.Control type="text" placeholder="تێبینی" value={note} onChange={(val) => setnote(val.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        if (amount == '' || amount < 1 || !amount) {
                            seterror(true);
                            return false;
                        }
                        if (exdate == '' || !exdate) {
                            seterror1(true);
                            return false;
                        }
                        createaction(amount, extype, location, exdate, note)(setloading);
                        props.setshow(false);
                    }}>
                        زیادکردن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Addexpensemodal