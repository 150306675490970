import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import Addexpensemodal from './addexpensemodal';
import searchaction from '../../context/action/expense/searchaction';
import Printexpense from './printexpense';
import Editexpensemodal from './editexpensemodal';
import numberbeautifuly from '../../../utils/numberbeautify';

const Expense = () => {
    const { dailyexpenseState, dailyexpenseDispatch } = useContext(GlobalContext);

    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const [from, setfrom] = useState(today);
    const [to, setto] = useState(today);
    const [show, setshow] = useState(false);
    const [show1, setshow1] = useState(false);
    const [expense_id, setexpense_id] = useState();
    const [expense_amount, setexpense_amount] = useState();
    const [expense_location, setexpense_location] = useState();
    const [expense_exdate, setexpense_exdate] = useState();
    const [expense_extype, setexpense_extype] = useState();
    const [expense_note, setexpense_note] = useState();
    return (
        <div dir="rtl">
            <Container>
                <Addexpensemodal show={show} setshow={setshow} />
                <Printexpense from={from} to={to} />
                <Editexpensemodal show={show1} setshow={setshow1} expense_id={expense_id} setexpense_id={setexpense_id} expense_amount={expense_amount} setexpense_amount={setexpense_amount} expense_location={expense_location} setexpense_location={setexpense_location} expense_exdate={expense_exdate} setexpense_exdate={setexpense_exdate} expense_extype={expense_extype} setexpense_extype={setexpense_extype} expense_note={expense_note} setexpense_note={setexpense_note} />
                <Row className='mt-4 print_hide'>
                    <center><h3><b> باخچەی منداڵان - خەرجی ڕۆژانە </b></h3></center>
                </Row>
                <Row className='mt-3 print_hide'>
                    <Col lg={1} />
                    <Col lg={2} className="mt-4">
                        <Button onClick={() => {
                            setshow(true);
                        }}>زیادکردن</Button>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>لە بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="لە بەرواری" value={from} onChange={(val) => { searchaction(val.target.value, to)(dailyexpenseDispatch); setfrom(val.target.value) }} />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>بۆ بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="بۆ بەرواری" value={to} onChange={(val) => { searchaction(from, val.target.value)(dailyexpenseDispatch); setto(val.target.value) }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='justify-content-md-center mt-5 print_hide'>
                    <Col lg={11}>
                        <Table striped bordered hover size="lg" style={{ textAlign: 'center' }}>
                            <thead>
                                <tr>
                                    <th>ژمارە</th>
                                    <th>بڕ</th>
                                    <th>شوێن</th>
                                    <th>بەروار</th>
                                    <th>جۆری خەرجی</th>
                                    <th>تێبینی</th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: 12 }}>
                                {dailyexpenseState.daily_expense.map((lis, index) => (
                                    <tr onClick={() => {
                                        setexpense_id(lis.daily_expense_id);
                                        setexpense_amount(lis.daily_expense_amount);
                                        setexpense_exdate(lis.daily_expense_date);
                                        setexpense_extype(lis.daily_expense_type);
                                        setexpense_location(lis.daily_expense_location);
                                        setexpense_note(lis.daily_expense_note);
                                        setshow1(true);
                                    }} style={{ cursor: 'pointer' }}>
                                        <td>{index + 1}</td>
                                        <td>{numberbeautifuly(lis.daily_expense_amount)} IQD</td>
                                        <td>{lis.daily_expense_location}</td>
                                        <td>{lis.daily_expense_date}</td>
                                        <td>{lis.daily_expense_type}</td>
                                        <td>{lis.daily_expense_note}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                    <Col lg={11}>
                        <Button variant='dark' disabled={dailyexpenseState.daily_expense.length > 0 ? false : true} onClick={() => { window.print() }}>پرینت</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Expense