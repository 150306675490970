import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { LoginContext } from '../../../home/logincontext';
import loanaction from '../../context/action/student/loanaction';

const Loanmodal = props => {
    const { setloading } = useContext(LoginContext);

    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const [datee, setdatee] = useState(today);
    const [amount, setamount] = useState();
    const [purpose, setpurpose] = useState();
    const [invoiceno, setinvoiceno] = useState();
    const [note, setnote] = useState();
    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label >ناوی کەس و مەبەستی داهات</Form.Label>
                                <Form.Control type="text" placeholder="ناوی کەس و مەبەستی داهات" value={purpose} onChange={(val) => setpurpose(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error ? "red" : null }}>بڕ</Form.Label>
                                <Form.Control style={{ borderColor: error ? "red" : null }} type="number" placeholder="بڕ" value={amount} onChange={(val) => setamount(val.target.value)} onFocus={() => seterror(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label >ژمارەی پسوڵە</Form.Label>
                                <Form.Control type="text" placeholder="ژمارەی پسوڵە" value={invoiceno} onChange={(val) => setinvoiceno(val.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10} className="mt-3">
                            <Form.Group controlId="formBasicName">
                                <Form.Label style={{ color: error1 ? "red" : null }}>بەرواری پسوڵە</Form.Label>
                                <Form.Control style={{ borderColor: error1 ? "red" : null }} type="date" placeholder="بەرواری پسوڵە" value={datee} onChange={(val) => setdatee(val.target.value)} onFocus={() => seterror1(false)} />
                            </Form.Group>
                        </Col>
                        <Col lg={10}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>تێبینی</Form.Label>
                                <Form.Control type="text" placeholder="تێبینی" value={note} onChange={(val) => setnote(val.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        if (amount == '' || amount < 0 || !amount) {
                            seterror(true);
                            return false;
                        }
                        if (datee == '' || !datee) {
                            seterror1(true);
                            return false;
                        }
                        loanaction(props.student_id, amount, purpose, invoiceno, datee, note)(setloading);
                        props.setshow(false);
                    }}>
                        زیادکردن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Loanmodal