import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import deleteexpenseaction from '../../../institute/context/action/student/deleteexpenseaction';
import deleteincomeaction from '../../context/action/student/deleteincomeaction';
import numberbeautifuly from '../../../utils/numberbeautify';

const Chekoutmodal = props => {
    const { studentState } = useContext(GlobalContext);
    const { loading, setloading } = useContext(LoginContext);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Modal.Header dir='rtl'>
                        <Modal.Title>لیستی ژمێریاری فێرخواز</Modal.Title>
                    </Modal.Header>
                    <Row className='justify-content-md-center mt-5'>
                        <Col lg={11}>
                            <Table striped bordered hover size="lg" style={{ textAlign: 'center' }} dir="rtl">
                                <thead>
                                    <tr>
                                        <th>ناو</th>
                                        <th>ژمارە</th>
                                        <th>جۆر</th>
                                        <th>بڕ</th>
                                        <th>بەروار</th>
                                        <th>تێبینی</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: 12 }}>
                                    {studentState.student_chekout.map((lis) => (
                                        lis.type == 'income' ?
                                            <tr style={{ cursor: 'pointer' }} onClick={() => {
                                                if (window.confirm("ئاگاداری ڕەشکردنەوە . دڵنیای ؟") == true) {
                                                    deleteincomeaction(lis.student_income_id)(setloading);
                                                    props.setshow(false);
                                                }
                                            }}>
                                                <td>{lis.student_name}</td>
                                                <td>{lis.student_phone1}</td>
                                                <td>داهات - {lis.student_income_type == 'other' ? "هیتر" : lis.student_income_type == 'scissors' ? "قیست" : "سزا"}</td>
                                                <td>{numberbeautifuly(lis.student_income_amount)} IQD</td>
                                                <td>{lis.date}</td>
                                                <td>{lis.student_income_note}</td>
                                            </tr>
                                            :
                                            <tr style={{ cursor: 'pointer' }} onClick={() => {
                                                if (window.confirm("ئاگاداری ڕەشکردنەوە . دڵنیای ؟") == true) {
                                                    deleteexpenseaction(lis.student_expense_id)(setloading);
                                                    props.setshow(false);
                                                }
                                            }}>
                                                <td>{lis.student_name}</td>
                                                <td>{lis.student_phone1}</td>
                                                <td>خەرجی - {lis.student_expense_type == "scissors" ? "قەرز" : "هیتر"}</td>
                                                <td>{numberbeautifuly(lis.student_expense_amount)} IQD</td>
                                                <td>{lis.date}</td>
                                                <td>{lis.student_expense_note}</td>
                                            </tr>
                                    ))}
                                    <tr>
                                        <td>تێکڕای ماوە</td>
                                        <td colSpan={5}>{numberbeautifuly(studentState.total_expense - studentState.total_income)} IQD</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" disabled={studentState.student_chekout.length > 0 ? false : true} onClick={() => {
                        // props.setstudent_detail();
                        props.setshow(false);
                        props.printit();
                    }}>
                        پرینت
                    </Button>
                    <Button variant="primary" onClick={() => {
                        props.setshow(false);
                    }}>
                        داخستن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Chekoutmodal