import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import constants from '../utils/constatnts';
export const LoginContext = createContext();
export const LoginProvider = (props) => {
    const [Token, setToken] = useState();
    const [role, setrole] = useState();
    const [loading, setloading] = useState(true);
    const [islogin, setislogin] = useState(false);
    useEffect(() => {
        const tokenString = localStorage.getItem('margrettoken');
        const roleString = localStorage.getItem('margretrole');
        console.log(tokenString);
        if (tokenString != null && tokenString != "null") {
            console.log('hhhayyyyyyy');
            setToken(JSON.parse(tokenString));
            setrole(roleString);
            setislogin(true);
            setloading(false);
        } else {
            console.log('nayyyyyyy');
            setislogin(false);
            setloading(false);
        }
    }, []);
    const clear = () => {
        setrole();
        setToken();
        setloading(false);
        setislogin(false);
        localStorage.setItem('margrettoken', null);
        localStorage.setItem('margretrole', null);
    }
    const check_login = (username, password, callback) => {
        setloading(true);
        var datas = {
            username: username,
            password: password
        };
        axios.post(constants.host + `login.php`, datas, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((resul) => {
            setloading(false);
            if (resul.data.status == "fail") {
                callback({
                    state: "wrong"
                })
            } else {
                setrole(resul.data.user_role);
                setToken(resul.data);
                setislogin(true);
                localStorage.setItem('margrettoken', JSON.stringify(resul.data));
                localStorage.setItem('margretrole', resul.data.user_role);

            }
        }).catch((e) => {
            console.log(e)
        });
    }
    return <LoginContext.Provider value={{ clear, check_login, Token, role, loading, setloading, islogin }}>{props.children}</LoginContext.Provider>
}