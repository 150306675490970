import React, { useState, useContext, useEffect } from 'react'; import './App.css';
import Home from './home/home';
import Loading from './home/loading';
import Login from './home/login';
import { LoginContext } from './home/logincontext';

function App() {
  const { islogin, loading } = useContext(LoginContext);
  return (
    <div>
      {loading ?
        <Loading />
        :
        islogin == true ?
          <Home />
          :
          <Login />
      }
    </div>
  );
}

export default App;
