import { useaxios } from "../../../../utils/useaxios";
export default (student_name, student_phone1, student_gender, student_birthday, student_note, student_cost, student_code, student_class, student_id) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setloading(true);
    useaxios('student/update.php', { student_id, student_name, student_gender, student_birthday, student_phone1, student_note, student_cost, student_code, class_id: student_class }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
        } else {
            // clear();
        }
        setloading(false);
    });
}