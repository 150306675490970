import { useaxios } from "../../../../utils/useaxios";
export default (student_id, amount, dat, note, type) => setloading => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    setloading(true);
    if (type == 'income') {
        useaxios('student_income/create.php', { student_income_type: 'other', student_income_amount: amount, student_income_note: note, student_income_date: dat, student_id, department_id: 4 }).then((res) => {
            console.log(res);
            if (res.status == "ok") {
            } else {
                // clear();
            }
            setloading(false);
        });
    } else {
        useaxios('student_expense/create.php', { student_expense_type: 'other', student_expense_amount: amount, student_expense_note: note, student_expense_date: dat, student_id, department_id: 4 }).then((res) => {
            console.log(res);
            if (res.status == "ok") {
            } else {
                // clear();
            }
            setloading(false);
        });
    }
}