import { useEffect, useReducer, createContext, useState } from "react";
import initstate from "./initialState/initstate";
import Initreducer from "./reducer/initreducer";
import initaction from '../context/action/init/init';

export const GlobalContext = createContext({});
const GlobalProvider = ({ children }) => {
    const [initState, initDispatch] = useReducer(Initreducer, initstate);
    const [recaller, setrecaller] = useState(0);

    useEffect(() => {
        initaction()(initDispatch);
    }, [recaller]);

    return <GlobalContext.Provider value={{ initState, initDispatch, recaller, setrecaller }}>{children}</GlobalContext.Provider>
}

export default GlobalProvider