const Studentreducer = (state, { type, payload }) => {
    switch (type) {
        case 'setstudent':
            return { ...state, student: payload.student, active_student: payload.active_student };
        case 'setsearch':
            return { ...state, student: payload };
        case 'setactivesearch':
            return { ...state, active_student: payload };
        case 'setchekout':
            return { ...state, student_chekout: payload.checkout, total_income: payload.total_income, total_expense: payload.total_expense };
        default:
            return state;
    }
}

export default Studentreducer;