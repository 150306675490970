import React, { useContext, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import './css/menu.css';
import './css/onprinttoggle.css';
import Image from 'react-bootstrap/Image';
import Logo from '../../margretlogo.png';
import { GiRead } from "react-icons/gi";
import { FaChalkboardTeacher, FaUserTie } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
const Menu = () => {
    const location = useLocation();
    return (
        <div className="usr_menu print_hide">
            <br />
            <center><Image src={Logo} style={{ width: 80, height: 90 }} /></center>
            <center><h6>پەیمانگە</h6></center>
            <ul>
                <Link to="/institute/"><li className={location.pathname == '/institute/' ? 'aactive' : null}> <GiRead style={{ marginLeft: 15 }} /> فێرخواز</li></Link>
                {/* <Link to="/institute/teacher"><li className={location.pathname == '/institute/teacher' ? 'aactive' : null}> <FaChalkboardTeacher style={{ marginLeft: 15 }} /> مامۆستا</li></Link> */}
                {/* <Link to="/institute/employ"><li className={location.pathname == '/institute/employ' ? 'aactive' : null}> <FaUserTie style={{ marginLeft: 15 }} /> کارمەند</li></Link> */}
                <Link to="/institute/studentaccount"><li className={location.pathname == '/institute/studentaccount' ? 'aactive' : null}><TbReportAnalytics style={{ marginLeft: 15 }} /> هەژماری فێرخواز</li></Link>
                {/* <Link to="/institute/teacheraccount"><li className={location.pathname == '/institute/teacheraccount' ? 'aactive' : null}><TbReportAnalytics style={{ marginLeft: 15 }} /> هەژماری مامۆستا</li></Link> */}
                {/* <Link to="/institute/employaccount"><li className={location.pathname == '/institute/employaccount' ? 'aactive' : null}><TbReportAnalytics style={{ marginLeft: 15 }} /> هەژماری کارمەند</li></Link> */}
                <Link to="/institute/dailyexpense"><li className={location.pathname == '/institute/dailyexpense' ? 'aactive' : null}><TbReportAnalytics style={{ marginLeft: 15 }} /> خەرجی ڕۆژانە </li></Link>
                <Link to="/institute/mainreport"><li className={location.pathname == '/institute/mainreport' ? 'aactive' : null}> <TbReportAnalytics style={{ marginLeft: 15 }} /> ژمێریاری گشتی</li></Link>
            </ul>
        </div>
    )
}

export default Menu