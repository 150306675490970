import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdEditNote } from "react-icons/md";
import Addincomemodal from './addincomemodal';
import Editincomemodal from './editincomemodal';
import numberbeautifuly from '../../../utils/numberbeautify';
import searchaction from '../../context/action/income/searchaction';

const Income = () => {
    const { initState, initDispatch } = useContext(GlobalContext);
    const { loading, setloading } = useContext(LoginContext);

    const [show, setshow] = useState(false);
    const [show1, setshow1] = useState(false);
    const [show2, setshow2] = useState(false);
    const [income_id, setincome_id] = useState();
    const [name, setname] = useState();
    const [amount, setamount] = useState();
    const [invoice, setinvoice] = useState();
    const [note, setnote] = useState();
    const [date, setdate] = useState();
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const [from, setfrom] = useState(today);
    const [to, setto] = useState(today);
    return (
        <div dir='rtl'>
            <Container>
                <Addincomemodal show={show} setshow={setshow} />
                <Editincomemodal show={show1} setshow={setshow1} name={name} setname={setname} amount={amount} setamount={setamount} invoice={invoice} setinvoice={setinvoice} note={note} setnote={setnote} date={date} setdate={setdate} income_id={income_id} />
                <Row className='mt-4'>
                    <center><h3><b>رادیۆی مارگرێت - داهات </b></h3></center>
                </Row>
                <Row className='mt-3'>
                    <Col lg={3} className="mt-4">
                        <Button onClick={() => {
                            setshow(true);
                        }}>زیادکردن</Button>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>لە بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="لە بەرواری" value={from} onChange={(val) => { searchaction(val.target.value, to)(initDispatch); setfrom(val.target.value) }} />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>بۆ بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="بۆ بەرواری" value={to} onChange={(val) => { searchaction(from, val.target.value)(initDispatch); setto(val.target.value) }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='justify-content-md-center mt-5'>
                    <Col lg={11}>
                        <Table striped bordered hover size="lg" style={{ textAlign: 'center' }} >
                            <thead>
                                <tr>
                                    <th>ناوی کۆمپانیا</th>
                                    <th>بڕی پارە</th>
                                    <th>بەروار</th>
                                    <th>ژمارەی پسوڵە</th>
                                    <th>تێبینی</th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: 12 }}>
                                {initState.income.map((lis) => (
                                    <tr style={{ cursor: 'pointer' }} onClick={() => {
                                        setincome_id(lis.radio_income_id);
                                        setname(lis.radio_income_name);
                                        setamount(lis.radio_income_amount);
                                        setinvoice(lis.radio_income_invoice);
                                        setnote(lis.radio_income_note);
                                        setdate(lis.radio_income_date);
                                        setshow1(true);
                                    }}>
                                        <td>{lis.radio_income_name}</td>
                                        <td>{numberbeautifuly(lis.radio_income_amount)} IQD</td>
                                        <td>{lis.radio_income_date}</td>
                                        <td>{lis.radio_income_invoice}</td>
                                        <td>{lis.radio_income_note}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Income