import { useaxios } from "../../../../utils/useaxios";
export default (company_name, invoiceno, from, to) => reportDispatch => {
    useaxios('company_expense/expense_report.php', { company_name, invoiceno, from, to }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            reportDispatch({ type: 'setexpensereport', payload: res.data });
        } else {
            // clear();
        }
    });
}