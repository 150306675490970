import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button'
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { GlobalContext } from '../../context/provider';
import expensereportaction from '../../context/action/report/expensereportaction';
import Expense_report_list from './expense_report_list';

const Expense_report = () => {
    const { reportState, reportDispatch } = useContext(GlobalContext);
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const [companyname, setcompanyname] = useState('');
    const [invoiceno, setinvoiceno] = useState('');
    const [from, setfrom] = useState();
    const [to, setto] = useState();
    return (
        <div dir="rtl">
            <Container className='print_hide'>
                <Row className='mt-3'>
                    <Col lg={3} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>ناوی کەس</Form.Label>
                            <Form.Control type="text" placeholder="ناوی کەس" value={companyname} onChange={(val) => setcompanyname(val.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>ژمارەی پسوڵە</Form.Label>
                            <Form.Control type="text" placeholder="ژمارەی پسوڵە" value={invoiceno} onChange={(val) => setinvoiceno(val.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>لە بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="لە بەرواری" value={from} onChange={(val) => setfrom(val.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>بۆ بەرواری</Form.Label>
                            <Form.Control type="date" placeholder="بۆ بەرواری" value={to} onChange={(val) => setto(val.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col lg={1} className="mt-5"
                        onClick={() => {
                            expensereportaction(companyname, invoiceno, from, to)(reportDispatch);
                        }}
                    >
                        <Button >گەڕان</Button>
                    </Col>
                </Row>
                <Expense_report_list />
            </Container>
        </div>
    )
}

export default Expense_report