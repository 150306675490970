const Reportreducer = (state, { type, payload }) => {
    switch (type) {
        case 'setreport':
            return { ...state, total_expense: payload.total_expense, expense: payload.expense, total_income: payload.total_income, income: payload.income, total: payload.total, isshow: true };
        case 'setincomereport':
            return { ...state, income_report: payload };
        case 'setexpensereport':
            return { ...state, expense_report: payload };
        default:
            return state;
    }
}

export default Reportreducer;