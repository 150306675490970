import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import Loanmodal from './loanmodal';
import Punishmodal from './punishmodal';
import Othermodal from './othermodal';
import activesearchaction from '../../context/action/student/activesearchaction';
import Scissorsmodal from './scissorsmodal';
import checkoutaction from '../../context/action/student/chekoutaction';
import Chekoutmodal from './chekoutmodal';
import Chekoutprint from './chekoutprint';
import '../css/onprinttoggle.css';
import numberbeautifuly from '../../../utils/numberbeautify';

const Studentaccount = () => {
    const { studentState, studentDispatch } = useContext(GlobalContext);
    const { loading, setloading } = useContext(LoginContext);

    const [search, setsearch] = useState();
    const [show, setshow] = useState(false);
    const [show1, setshow1] = useState(false);
    const [show2, setshow2] = useState(false);
    const [show3, setshow3] = useState(false);
    const [show4, setshow4] = useState(false);
    const [student_id, setstudent_id] = useState(0);
    const [student_detail, setstudent_detail] = useState([]);

    const printit = async () => {
        setshow4(false);
        setTimeout(() => { window.print(); }, 250);
    }
    return (
        <div dir='rtl'>
            <Chekoutprint />
            <Container className='print_hide'>
                <Row className='mt-4'>
                    <center><h3><b> دایەنگە - هەژماری فێرخواز </b></h3></center>
                </Row>
                <Loanmodal student_id={student_id} show={show} setshow={setshow} />
                <Scissorsmodal student_id={student_id} show={show3} setshow={setshow3} />
                <Punishmodal student_id={student_id} show={show1} setshow={setshow1} />
                <Othermodal student_id={student_id} show={show2} setshow={setshow2} />
                <Chekoutmodal show={show4} setshow={setshow4} printit={printit} student_detail={student_detail} setstudent_detail={setstudent_detail} />
                <Row className='mt-3'>
                    <Col lg={1} />
                    <Col lg={5}>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>گەڕان</Form.Label>
                            <Form.Control type="text" placeholder="گەڕان" value={search} onChange={(val) => activesearchaction(val.target.value)(studentDispatch)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='justify-content-md-center mt-5'>
                    <Col lg={11}>
                        <Table striped bordered hover size="lg" style={{ textAlign: 'center' }}>
                            <thead>
                                <tr>
                                    <th>کۆد</th>
                                    <th>ناو</th>
                                    <th>پۆل</th>
                                    <th>ژمارەی وەسڵ</th>
                                    <th>تێچوی خوێندن</th>
                                    <th>دۆخ</th>
                                    <th>ڕێکخستن</th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: 12 }}>
                                {studentState.active_student.map((lis) => (
                                    <tr>
                                        <td>{lis.student_id}</td>
                                        <td onClick={() => { checkoutaction(lis.student_id)(setloading)(studentDispatch)(setshow4) }} style={{ cursor: "pointer" }}>{lis.student_name}</td>
                                        <td>{lis.class_name}</td>
                                        <td>{lis.student_code}</td>
                                        <td>{numberbeautifuly(lis.student_cost)} IQD</td>
                                        <td>{lis.student_state}</td>
                                        <td>
                                            <a style={{ marginRight: 4 }} href='#'
                                                onClick={() => {
                                                    setstudent_id(lis.student_id);
                                                    setshow(true);
                                                }}
                                            >قەرز</a>
                                            <a style={{ marginRight: 4 }} href='#'
                                                onClick={() => {
                                                    setstudent_id(lis.student_id);
                                                    setshow1(true);
                                                }}
                                            >سزا</a>
                                            <a style={{ marginRight: 4 }} href='#'
                                                onClick={() => {
                                                    setstudent_id(lis.student_id);
                                                    setshow3(true);
                                                }}
                                            >قیست</a>
                                            <a style={{ marginRight: 4 }} href='#'
                                                onClick={() => {
                                                    setstudent_id(lis.student_id);
                                                    setshow2(true);
                                                }}
                                            >هی دیکە</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Studentaccount