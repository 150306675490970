import { useaxios } from "../../../../utils/useaxios";
export default (date) => employDispatch => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    useaxios('employ/salarylist.php', { date }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            alert('لیستی موچە چەسپێرا');
            window.open('https://managment.margaret.edu.krd/employ/', "_self");
            // window.
            // employDispatch({ type: 'setsalarylist', payload: res.employs });
        } else {
            // clear();
        }
    });
}