import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { GlobalContext } from '../../context/provider';
import { LoginContext } from '../../../home/logincontext';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdEditNote } from "react-icons/md";
import getsalaryaction from '../../context/action/salary/getsalaryaction';
import salary_listaction from '../../context/action/employ/salary_listaction';
import Printsalarylist from './printsalarylist';
import numberbeautify from '../../../utils/numberbeautify';

const Salarylist = () => {
    const { employState, employDispatch } = useContext(GlobalContext);
    const [salarydate, setsalarydate] = useState();
    const [error, seterror] = useState(false);

    return (
        <div dir="rtl">
            <Container>
                <Printsalarylist date={salarydate} />
                <Row className='mt-4 print_hide'>
                    <center><h3><b> لیستی موچە </b></h3></center>
                </Row>
                <Row className='justify-content-md-center mt-3 print_hide'>
                    <Col lg={6} className="mt-3">
                        <Form.Group controlId="formBasicName">
                            <Form.Label style={{ color: error ? "red" : null }}>بەرواری لیستی موچە</Form.Label>
                            <Form.Control style={{ borderColor: error ? "red" : null }} type="date" value={salarydate} placeholder="بەرواری لیستی موچە" onChange={(val) => setsalarydate(val.target.value)} onFocus={() => seterror(false)} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mt-5">
                        <Button variant="primary" onClick={() => {
                            if (!salarydate || salarydate == "") {
                                seterror(true);
                                return false;
                            }
                            getsalaryaction(salarydate)(employDispatch);
                        }}>
                            ڕاکێشان
                        </Button>
                    </Col>
                </Row>
                {employState.salary_list.length > 0 ?
                    <Row className='justify-content-md-center mt-5 print_hide'>
                        <Col lg={11}>
                            <Table striped bordered hover size="lg" style={{ textAlign: 'center' }}>
                                <thead style={{ fontsize: 10 }}>
                                    <tr>
                                        <th>ناو</th>
                                        <th>بڕی پارە</th>
                                        <th>پیشە</th>
                                        <th>بەشە وانە</th>
                                        <th>ئیسمی</th>
                                        <th>بڕوانامە</th>
                                        <th>خزمەت</th>
                                        <th>کۆمیشنی ڕێککەوتن</th>
                                        <th>شوێن</th>
                                        <th>پاداشت</th>
                                        <th>سزا</th>
                                        <th>تێبینی</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: 10 }}>
                                    {employState.salary_list.map((lis) => (
                                        <tr>
                                            <td>{lis.employ_name}</td>
                                            <td>{numberbeautify(lis.main_salary)} IQD</td>
                                            <td>{lis.employ_job}</td>
                                            <td>{lis.lesson_part}</td>
                                            <td>{numberbeautify(lis.employ_salary)} IQD</td>
                                            <td>{lis.certificate_name}</td>
                                            <td>{numberbeautify(lis.employ_service_amount)} IQD</td>
                                            <td>{numberbeautify(lis.employ_agrement_amount)} IQD</td>
                                            <td>{lis.department_name}</td>
                                            <td>{numberbeautify(lis.empenses)}</td>
                                            <td>{numberbeautify(lis.incomes)}</td>
                                            <td></td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <th style={{ textAlign: 'center' }} colSpan={8}>کۆی گشتی</th>
                                        <td style={{ textAlign: 'center' }} colSpan={4}>{numberbeautify(employState.salary_list.reduce((a, v) => a = Number(a) + Number(v.main_salary), 0))} IQD</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col lg={11}>
                            <Button variant='dark' className='mb-3' disabled={employState.salary_list.length > 0 ? false : true} onClick={() => { window.print() }} style={{ marginLeft: '1%' }}>چاپکردن</Button>
                            <Button className='mb-3' onClick={() => {
                                if (window.confirm("ئاگاداری چەسپاندنی موچە . دڵنیای ؟") == true) {
                                    salary_listaction(salarydate)(employDispatch);
                                }
                            }}>چەسپاندنی لیستی موچە</Button>
                        </Col>
                    </Row>
                    :
                    <div />}
            </Container>
        </div>
    )
}

export default Salarylist