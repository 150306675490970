import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Image from 'react-bootstrap/Image';
import { Summarycontext } from './summarycontext';
import moment from "moment";
import Logo from '../../../margretlogo.png';
import '../css/onprinttoggle.css';
import numberbeautifuly from '../../../utils/numberbeautify';


const Printsummary = props => {
    const { getsummary, totalsummary, settotalsummary, showsummary, setshowsummary } = useContext(Summarycontext);

    return (
        <div className='print_show'>
            <Container>
                <Row className='justify-content-md-center mt-3'>
                    <Image src={Logo} style={{ width: 110, height: 100 }} />
                    <center><span>پوختەی حساباتی مارگرێت لە بەرواری {props.from} بۆ  {props.to}</span></center>
                    <center><span>داهات</span></center>
                </Row>
                <Row className='justify-content-md-center mt-3'>
                    <table>
                        <thead style={{ border: '2px solid', textAlign: 'center' }}>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>داهات</th>
                                <th>بڕی پارە</th>
                            </tr>
                        </thead>
                        <tbody style={{ border: '2px solid', textAlign: 'center' }}>
                            <tr style={{ border: '2px solid' }}>
                                <td>داهاتی پەیمانگە</td>
                                <td>{numberbeautifuly(totalsummary.institute_total_income)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>داهاتی خوێندنگە</td>
                                <td>{numberbeautifuly(totalsummary.school_total_income)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>داهاتی باخچەی مناڵان</td>
                                <td>{numberbeautifuly(totalsummary.kindergarten_total_income)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>داهاتی دایەنگە</td>
                                <td>{numberbeautifuly(totalsummary.nursery_total_income)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>داهاتی هاوکاریەکانی کەریمی عەلەکە</td>
                                <td>{numberbeautifuly(totalsummary.boss_total_income)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>داهاتی ڕادیۆ</td>
                                <td>{numberbeautifuly(totalsummary.radio_total_income)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>داهاتی پڕۆژە</td>
                                <td>{numberbeautifuly(totalsummary.project_total_income)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>داهاتی کۆمپانیا</td>
                                <td>{numberbeautifuly(totalsummary.company_total_income)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>کۆی داهات</td>
                                <td>{numberbeautifuly(totalsummary.institute_total_income + totalsummary.school_total_income + totalsummary.kindergarten_total_income + totalsummary.nursery_total_income + totalsummary.boss_total_income + totalsummary.radio_total_income + totalsummary.project_total_income + totalsummary.company_total_income)} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <center><span>خەرجی</span></center>
                <Row className='justify-content-md-center mt-4'>
                    <table>
                        <thead style={{ border: '2px solid', textAlign: 'center' }}>
                            <tr style={{ border: '2px solid', textAlign: 'center' }}>
                                <th>خەرجی</th>
                                <th>بڕی پارە</th>
                            </tr>
                        </thead>
                        <tbody style={{ border: '2px solid', textAlign: 'center' }}>
                            <tr style={{ border: '2px solid' }}>
                                <td>خەرجی پەیمانگە</td>
                                <td>{numberbeautifuly(totalsummary.institute_total_expense)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>خەرجی خوێندنگە</td>
                                <td>{numberbeautifuly(totalsummary.school_total_expense)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>خەرجی باخچەی مناڵان</td>
                                <td>{numberbeautifuly(totalsummary.kindergarten_total_expense)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>خەرجی دایەنگە</td>
                                <td>{numberbeautifuly(totalsummary.nursery_total_expense)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>خەرجی و هاوکاریەکانی کەریمی عەلەکە</td>
                                <td>{numberbeautifuly(totalsummary.boss_total_expense)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>خەرجی ڕادیۆ</td>
                                <td>{numberbeautifuly(totalsummary.radio_total_expense)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>خەرجی پڕۆژە</td>
                                <td>{numberbeautifuly(totalsummary.project_total_expense)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>خەرجی کۆمپانیا</td>
                                <td>{numberbeautifuly(totalsummary.company_total_expense)} IQD</td>
                            </tr>
                            <tr style={{ border: '2px solid' }}>
                                <td>کۆی خەرجی</td>
                                <td>{numberbeautifuly(totalsummary.institute_total_expense + totalsummary.school_total_expense + totalsummary.kindergarten_total_expense + totalsummary.nursery_total_expense + totalsummary.boss_total_expense + totalsummary.radio_total_expense + totalsummary.project_total_expense + totalsummary.company_total_expense)} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <center><span>پوختە</span></center>
                <Row className='justify-content-md-center mt-4'>
                    <table>
                        <tbody style={{ border: '2px solid', textAlign: 'center' }}>
                            <tr style={{ border: '2px solid' }}>
                                <td>پوختەی داهات</td>
                                <td>{numberbeautifuly((totalsummary.institute_total_income + totalsummary.school_total_income + totalsummary.kindergarten_total_income + totalsummary.nursery_total_income + totalsummary.boss_total_income + totalsummary.radio_total_income + totalsummary.project_total_income + totalsummary.company_total_income) - (totalsummary.institute_total_expense + totalsummary.school_total_expense + totalsummary.kindergarten_total_expense + totalsummary.nursery_total_expense + totalsummary.boss_total_expense + totalsummary.radio_total_expense + totalsummary.project_total_expense + totalsummary.company_total_expense))} IQD</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <div style={{ width: '100%', marginTop: '5vh', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%' }}>
                        <span>سارا سعدی عمر<br />
                            ژمێریار</span>
                    </div>
                    <div style={{ width: '50%', display: 'flex', justifyContent: "flex-end" }}>
                        <span>ئاڤان ئەحمەد حەمە<br />
                            بەڕێوەبەر</span>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Printsummary