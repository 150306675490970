import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { LoginContext } from '../../../home/logincontext';
import { GlobalContext } from '../../context/provider';
import numberbeautifuly from '../../../utils/numberbeautify';

const Expense_report_detail_modal = props => {
    const { reportState, reportDispatch } = useContext(GlobalContext);
    return (
        <div dir="rtl">
            <Modal
                show={props.show}
                onHide={() => props.setshow(false)}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Body>
                    <Modal.Header dir='rtl'>
                        <Modal.Title>لیستی خەرجی</Modal.Title>
                    </Modal.Header>
                    <Row className="justify-content-md-center mt-4" dir='rtl'>
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr>
                                    <th>موچە</th>
                                    <td>{numberbeautifuly(reportState.expense_salary)} IQD</td>
                                </tr>
                                <tr>
                                    <th>خەرجی ڕۆژانە</th>
                                    <td>{numberbeautifuly(reportState.daily_expense)} IQD</td>
                                </tr>
                                <tr>
                                    <th>خەرجی تر</th>
                                    <td>{numberbeautifuly(reportState.expense_other)} IQD</td>
                                </tr>
                                <tr>
                                    <th>کۆی خەرجی</th>
                                    <td>{numberbeautifuly(reportState.total_expense)} IQD</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => {
                        props.setshow(false);
                        props.setreporttype('expense');
                        props.printit();
                    }}>
                        پرینت
                    </Button>
                    <Button variant="primary" onClick={() => {
                        props.setreporttype('');
                        props.setshow(false);
                    }}>
                        داخستن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Expense_report_detail_modal